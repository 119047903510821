import React from 'react';

import Index, { IndexProps } from 'components/Layout';
import SEO from 'components/seo';
import TOS from 'components/TOS';
import PageWrapper from 'components/PageWrapper';

const TOSPage: React.FC<IndexProps> = ({ pageContext }) => (
  <Index pageContext={pageContext}>
    <SEO pageTitleTermPath="tos" />
    <PageWrapper withBottomBackground>
      <TOS />
    </PageWrapper>
  </Index>
);

export default TOSPage;
