import React from 'react';

import { useIntl } from 'react-intl';

import FrenchText from './FrenchText';
import EnglishText from './EnglishText';

import styles from './TOS.module.scss';

const TOS: React.FC = () => {
  const { locale } = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {locale === 'fr' ? (
          <FrenchText />
        ) : (
          <EnglishText />
        )}
      </div>
    </div>
  );
};

export default TOS;
