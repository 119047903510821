/* eslint-disable react/jsx-no-literals,react/no-unescaped-entities,max-len */
import React from 'react';

const EnglishText: React.FC = () => (
  <>
    <h2 className="section-title center-text">
      GENERAL TERMS AND CONDITIONS OF USE OF ONOFF TELECOM
    </h2>

    <p>
      Before accessing the Services offered by ONOFF TELECOM, the User is invited to read carefully
      these general terms and conditions of use. They define the terms of provision of the Services
      and the conditions of use of the Application.
    </p>

    <p>
      The author is the company ONOFF TELECOM, a simplified joint stock company, registered in the
      Paris Trade and Companies Register under number 832 235 253 and having its registered office
      at 26 boulevard de Bonne Nouvelle 75010, Paris, France, ("ONOFF TELECOM") . ONOFF TELECOM has
      developed an Application called ONOFF offering secondary contact management and communications
      services.
    </p>

    <p>
      Access to the Services by the User is subject to prior acceptance of the Terms of Use.
    </p>

    <p>
      The Terms of Use can only be validly accepted by a user (a) legally authorized to use the
      Application in the country where he is located and (b) of legal age to conclude a legally
      binding contract with ONOFF TELECOM.
    </p>

    <h3>1. Définitions</h3>

    <p>
      In these Terms of Use, the following terms have, unless otherwise specified, the following
      meanings:
    </p>

    <p>
      <strong>Operator Subscription</strong>
      {' '}
      : refers to the contract for mobile telephony and mobile broadband Internet services signed by
      the User with a third-party operator prior to the use of the Services and independently of
      ONOFF TELECOM.
    </p>

    <p>
      <strong>Application</strong>
      {' '}
      : means any application (including the software called ONOFF), developed and operated by ONOFF
      TELECOM, downloadable on the application distribution platforms and allowing the User to
      benefit from the Services. The term Application also refers to the solution allowing the use
      of Numbers through applications belonging to third parties (Microsoft Teams or Zoom).
    </p>

    <p>
      <strong>User Account</strong>
      {' '}
      : refers to the personal account created by the User in order to use the Application.
    </p>

    <p>
      <strong>Terms of Use</strong>
      {' '}
      : refers to these general conditions of use governing, together with the Personal Data
      Protection Policy, the contractual relations relating to the provision of the Services by
      ONOFF TELECOM to the User.
    </p>

    <p>
      <strong>User Content</strong>
      {' '}
      : means the content published by the User via the Application.
    </p>

    <p>
      <strong>ONOFF Credits</strong>
      {' '}
      : refers to credits to purchase Services, including Numbers, make phone calls and/or send SMS.
    </p>

    <p>
      <strong>Number(s)</strong>
      {' '}
      : refers to the mobile phone number(s) of the national numbering plan assigned to the User by
      ONOFF TELECOM and allowing the identification of the User via the Application.
    </p>

    <p>
      <strong>Personal Data Protection Policy</strong>
      {' '}
      : refers to the personal data protection policy defined by ONOFF TELECOM and available at
      {' '}
      <a href="https://www.onoff.app/privacy-policy/">
        https://www.onoff.app/privacy-policy/
      </a>
      .
    </p>

    <p>
      <strong>Service(s)</strong>
      {' '}
      : refers to all secondary services for managing paid or free contacts and communications
      offered by ONOFF TELECOM and accessible from mobile access by the User via the Application in
      accordance with the Terms of Use.
    </p>

    <p>
      <strong>Website</strong>
      {' '}
      : refers to the site accessible at
      {' '}
      <a href="www.onoff.app">
        www.onoff.app
      </a>
      , operated by ONOFF TELECOM.
    </p>

    <p>
      <strong>User</strong>
      {' '}
      : means the holder of a User Account and the holder of a license to use the Application in
      accordance with the Terms of Use.
    </p>

    <h3>2. Purpose of the Terms of Use</h3>

    <p>
      The Terms of Use apply to the Services marketed and operated by ONOFF TELECOM and are intended
      to define the practical and financial conditions under which the User will be able to (i)
      create his User Account, (ii) be granted a non-exclusive license to use the Application and
      (iii) be able to use the Application and the Services.
    </p>

    <p>
      ONOFF TELECOM may, by operation of law, modify its service offers and/or make changes to the
      Terms of Use without any other formality than to inform the User in advance by any means at
      his convenience. In the event that the User does not wish to accept the application of the new
      Terms of Use, he may notify his wish to terminate the Contract entered into with ONOFF
      TELECOM. The fact that the User does not terminate the Contract will carry his unreserved
      acceptance of the new version of the Terms of Use.
    </p>

    <p>
      Access to the Services is subject to prior acceptance of the Terms of Use. The User will
      accept the Terms of Use (i) by clicking to accept the Terms of Use or (ii) by creating his
      User Account.
    </p>

    <p>
      If access to the Application and/ or services has been through a third party, separate terms
      of use presented by this third party may also apply to the user's access to the Services.
      These conditions will also be applicable to the provision of the Services and must be
      respected by the User.
    </p>

    <h3>3. Access to and use of the Application and Services</h3>

    <p>
      Subscription to the Services may be made, as the case may be, directly with ONOFF TELECOM or
      with a third party, such as an application distribution platform.
    </p>

    <p>
      Access to and use of the Services require (i) the use of the SIM card associated with the
      Operator Subscription, (ii) a terminal allowing the use of the Services (Smartphone, PDA,
      tablet or equivalent) and having a recent version operating system and (iii) access to the
      Internet network.
    </p>

    <p>
      The operation of the Application and the Services requires the prior subscription by the User
      - at his own expense - of an Operator Subscription including (i) the possibility of making and
      receiving telephone calls and / or SMS and (ii) high-speed mobile Internet access as well as
      the corresponding telephone credit.
    </p>

    <p>
      The subscription and maintenance in force of the Operator Subscription, essential for the use
      of the Application, are the sole responsibility of the User. The User must be equipped with a
      terminal allowing the use of the Application (Smartphone, PDA, tablet or equivalent) and
      having a recent version operating system and regularly updated. The User is solely responsible
      for the proper functioning of his terminals.
    </p>

    <p>
      The User acknowledges that the effective use of the Application depends on the availability of
      communications and high-speed Internet services of the Operator Subscription he has subscribed
      to. In addition, usage limits and geographic restrictions specific to the Application may
      vary. Thus, the User is advised to regularly consult the Website in order to know in more
      detail the restrictions of use and the recent changes made to the Application.
    </p>

    <p>
      Once the Application is installed on his terminal, the User will be able to access his User
      Account with the username and password that he will have defined when creating the
      UserAccount. His password is personal and confidential and must not be shared with any third
      party in order to prevent any unauthorized use of the User Account. The User is solely
      responsible for the confidentiality of his password.
    </p>

    <p>
      ONOFF TELECOM reserves the right to suspend, refuse or cancel the subscription to the Contract
      or to suspend the performance of the Contract if one of the following conditions is not
      respected (i) the settlement at maturity of all debts contracted by the User with ONOFF
      TELECOM under another contract, (ii) the provision of all the supporting documents requested
      by ONOFF TELECOM, (iii) the agreement given by the bank payment centers concerned and (iv) the
      exact statements of the User. In this case, the subscription request will be automatically
      refused or cancelled and the User notified by email.
    </p>

    <p>
      During the contract, ONOFF TELECOM reserves the right to carry out and/or have carried out any
      additional investigation, in particular on the elements transmitted by the User and, in case
      of reasonable doubt about the user of the Service or the documents that have been transmitted
      in support of the subscription, to refuse or cancel any subscription to the Contract. In this
      case, the subscription request will be automatically refused or cancelled and the User
      notified by email.
    </p>

    <p>
      ONOFF TELECOM reserves the right to delete at any time a User Account that does not comply
      with the applicable legal provisions, the rights of third parties or that does not comply with
      the Terms of Use. This would be the case, for example, of a User Account created on the basis
      of a false surname or an invalid e-mail address.
    </p>

    <h3>4. Description of the Services</h3>

    <p>
      Once the registration steps have been completed, the User may obtain from ONOFF TELECOM one or
      more Numbers and/or subscribe to one or more call packages and benefit from the associated
      features. Depending on the regulations applicable to subscribed Numbers, ONOFF TELECOM may be
      required to request additional information to that collected during the creation of the User
      Account relating to the identity or location of the User.
    </p>

    <p>
      The assignment of a Number gives access to all linked Services. The Numbers make it possible
      to make and receive telephone calls and/ or, where appropriate, SMS and/or MMS to and from any
      person with a telephone number subject to the limitations set out in the following paragraph
      concerning calls to certain classes of numbers.
    </p>

    <p>
      The Services are in no way a substitute for the primary mobile telephony service provided by
      the operator with whom the User has subscribed to his Operator Subscription. The User
      acknowledges and accepts that the Numbers do not allow calls to be made to all numbers.
      Consequently, calls that cannot be made from the Application (i.e. in particular but not
      exhaustively calls to special numbers and emergency numbers) must be made directly from the
      number associated with the User's Operator Subscription.
    </p>

    <h3>5. Restrictions on use of the Application</h3>

    <p>
      The User must make normal use of the Application and the associated Services and acknowledges
      that ONOFF TELECOM may put in place measures to prevent any obvious misuse of the purpose of
      the Services.
    </p>

    <p>
      ONOFF TELECOM will evaluate the normal use of the Services in comparison with the uses of
      other Users. Consumption will be considered abnormal, in that it will be considered to
      constitute a diversion of the purpose of the Services, if it deviates substantially from the
      average consumption of Users.
    </p>

    <p>
      The User is further informed that the following uses, without this list being considered
      exhaustive, are considered abnormal:
    </p>

    <ul>
      <li>
        the issuance of more than fifteen (15) calls in less than three (3) minutes from a User
        Account,
      </li>

      <li>
        the issuance of calls of more than 2 hours from a User account,
      </li>

      <li>
        the use of the Application and / or the Services for the realization of a marketing campaign
        for commercial purposes, spamming, the provision of automated services,
      </li>

      <li>
        the resale or attempted resale of the Services to third parties,
      </li>

      <li>
        the use of Numbers from a given country without being able to justify a stable link
        involving a frequent and significant presence in that country.
      </li>
    </ul>

    <p>
      If ONOFF TELECOM detects an abnormal use of the Services, it will notify the User of the
      abnormal nature of the use of the Services. ONOFF TELECOM may then immediately suspend the
      Services temporarily or permanently, without its liability being incurred under this
      suspension.
    </p>

    <p>The User must use the Application in compliance with applicable law and regulations</p>

    <p>
      The User must in no case make unauthorized use of the Application or Services, that is to say
      contrary to the above. The User is further informed that the following uses, without this list
      being considered exhaustive, are considered unauthorized:
    </p>

    <ul>
      <li>
        harm or attempt to harm the Application, and in particular:
        <ul>
          <li>
            use a method designed to distort, alter, modify, disassemble, erase the Application,
            protocols and communications, such as, but not limited to, viruses, Trojan horses, a
            worm, logic bombs, etc.;
          </li>

          <li>
            alter or attempt to alter the Application, its functionality or availability;
          </li>

          <li>
            circumvent, disable or disrupt functions related to the security of the Application
            and its integrity (or that of any electronic equipment whatsoever), or functions that
            prevent or limit the use of the content of the Software;
          </li>

          <li>
            use an automated system in order to access the Application.
          </li>
        </ul>
      </li>

      <li>
        upload and/or share inappropriate content (illegal, offensive to human dignity, sexual,
        defamatory, abusive, racist or any other content that may engage the civil or criminal
        liability of the person who publishes it);
      </li>

      <li>
        engage in activities that infringe on the privacy of others, including by impersonating
        another person;
      </li>

      <li>
        modify, intercept or monitor any communication not intended for it;
      </li>

      <li>
        harvest information from the Application that would allow personal identification including
        account names;
      </li>

      <li>
        use the Application in order to send, or help others to send, unwanted messages. All
        unsolicited messages and contact requests ("spam", "SPIT or Spam over Internet Telephony",
        "junk mail" or misleading and misleading content) and sent in bulk, whether in the form of
        e-mails, calls, instant messages or other, are considered to be undesirable;
      </li>

      <li>
        use the Application for phishing, identity theft or misdirection;
      </li>

      <li>
        use the Application to embarrass, threaten, harass or invade the privacy of a third party,
        or expose a third party to offensive, indecent or dangerous content;
      </li>

      <li>
        Infringe the intellectual property rights of ONOFF TELECOM, another User, or a third party,
        and in particular:
        <ul>
          <li>
            use in any way whatsoever any content subject to proprietary rights not belonging to
            the User, unless the User holds a license or the express permission of the owner of the
            rights;
          </li>

          <li>
            resell or market the Application, while it is intended for the User's personal use.
          </li>
        </ul>
      </li>
    </ul>

    <li>
      In addition, the User is expressly reminded that, in accordance with regulations establishing
      the national numbering plan and its management rules, any Number may not be used as a
      "secondary number" for the provision of telephone or messaging services by SMS or MMS, the
      subscriber of which can be reached without any restriction by any user of a telephone service
      to the public or sms or mms that since these services can only be used from mobile access.
    </li>

    <li>
      It is also recalled that this "secondary" number may be provided by an operator different from
      the one that provides mobile access to the User.
    </li>

    <li>
      Finally, it is recalled that a Number can only be assigned to a User habitually or temporarily
      residing, or justifying stable links involving a frequent and significant presence, in the
      territory corresponding to this Number.
    </li>

    <li>
      If ONOFF TELECOM detects an unauthorized use of the Application or Services, it will notify
      the User. ONOFF TELECOM may then immediately suspend the Services temporarily or permanently
      and/or terminate the contract with the User, without its liability being incurred for such
      suspension or termination.
    </li>

    <h3>6. Update and maintenance</h3>

    <p>
      ONOFF TELECOM may at any time update the Application.
    </p>

    <p>
      These updates may be intended to maintain software compatibility, offer new features or
      versions of the Application, provide security updates or bug fixes, etc. ONOFF TELECOM
      reserves the right to check the versions of the Application and download configuration changes
      and updates to the Application. ONOFF TELECOM does not guarantee that the updated Application
      is always compatible with older versions of the Application. ONOFF TELECOM also does not
      guarantee to continue to support a version of the operating system or device for which the
      User has a License.
    </p>

    <p>
      The User acknowledges that he has to constantly use an up-to-date version of the Application.
    </p>

    <p>
      ONOFF TELECOM may modify the Application at any time and for this purpose to carry out
      maintenance or upgrade operations of the Application or the underlying infrastructure allowing
      the use of the Application. ONOFF TELECOM may in this context temporarily suspend or limit the
      use of all or part of the Application or services. Except in cases of emergency, temporary
      interruptions will, as far as possible, be notified on the Website before they occur. ONOFF
      TELECOM will in no case be liable for damages to the User in the event of suspension or
      limitation of the use of the Application due to the conduct of maintenance operations.
    </p>

    <h3>7. Terms of Use of the Services</h3>

    <p>
      By being assigned a Number, the User accepts the following:
    </p>

    <ul>
      <li>
        the User has no ownership right over the Number(s), nor does it have any right to the
        permanent storage or maintenance of the Number(s);
      </li>

      <li>
        the User must use the Application in compliance with applicable law and regulations and the
        Terms of Use;
      </li>

      <li>
        the User must comply at all times with the applicable numbering rules and the special
        requirements for the assignment of Numbers as well as with any instructions that ONOFF
        TELECOM may send him with regard to the Number(s);
      </li>

      <li>
        the User has a revocable right to use the Number, excluding in particular its assignment,
        subletting, transfer, portability or more generally any act of disposition or security.
      </li>
    </ul>

    <p>
      ONOFF TELECOM reserves the right to modify the conditions of access to the Numbers and
      provision of the Services, in particular in the event of a change in the applicable legal and
      regulatory provisions or to take into account any administrative or judicial decision that
      would be applicable to the terms of provision of the Numbers and associated Services.
    </p>

    <p>
      The User acknowledges and accepts that the transmission and reception of telephone calls and
      SMS/MMS may require the use of the SIM card associated with the Operator Subscription and the
      corresponding telephone and/or data credits. Calls/SMS/MMS made and received from the
      Application are counted and/or invoiced, as the case may be, as part of the User's Operator
      Subscription.
    </p>

    <p>
      The use of the Application and consequently access to the Services require access to the
      Internet network. The conditions of access to the Internet network are defined by the User's
      access provider as part of the Operator Subscription.
    </p>

    <p>
      ONOFF TELECOM reserves the right to suspend access to the Services at any time to any User who
      would use the Application in violation of applicable legal and regulatory provisions, the
      rights of third parties or the Terms of Use.
    </p>

    <h3>8. Quality of Service</h3>

    <p>
      ONOFF TELECOM applies the security measures necessary for the prevention of incidents and
      implements the means allowing it to react to an incident to limit the unavailability of the
      Services and the impact on users' data.
    </p>

    <p>
      ONOFF TELECOM undertakes to achieve, in the covered area, the following levels of service:
    </p>

    <ul>
      <li>
        availability of the Services within a maximum period of six (6) hours from the creation of
        the User Account and the assignment of the Services to the User,
      </li>

      <li>
        a monthly availability of the Services at least equal to 90%, the availability of the
        Service being understood to mean the material possibility of transmitting or receiving a
        communication,
      </li>

      <li>
        a reinstatement of the Services within a maximum period of forty-eight (48) hours from the
        notification of the interruption of the Services to the customer support service (the
        "Support") of ONOFF TELECOM accessible through the Website. Interruption of the Service
        means the material impossibility of transmitting or receiving a communication.
      </li>
    </ul>

    <p>
      The service levels apply when the following conditions are cumulatively met: (i) the User is
      located in an area usually covered by the Service and its Operator Subscription and (ii) has a
      terminal compatible with the Application, lit and in perfect working order.
    </p>

    <p>
      In the event that ONOFF TELECOM does not reach one of the aforementioned levels of service,
      the User will have a period of one (1) month to send a request for compensation specifying the
      User, the incident concerned and its duration to the Support. ONOFF TELECOM undertakes to
      process requests for compensation (i) within five (5) working days of their receipt with
      regard to the compliance of the User's request with the conditions detailed in this article
      and (ii) within one month of their receipt for their full examination. If the request is
      justified, ONOFF TELECOM will award theaffectedUser compensation equivalent to one (1) month
      of subscription to the affected Service.
    </p>

    <p>
      However, such claims for compensation shall not be admissible in the following cases:
    </p>

    <ul>
      <li>
        in the absence of a request for compensation made by the User within one (1) month of
        non-compliance with the aforementioned levels of services,
      </li>

      <li>
        in the event of fault on the part of the User concerned or misuse by the User and/or his
        correspondents of the Service,
      </li>

      <li>
        in case of incorrect configuration of the Application,
      </li>

      <li>
        in case of non-compliant use of the Application,
      </li>

      <li>
        in the event of the transmission of radio signals affected by the constraints or limits of
        the technical standards imposed on the operator with whom the Operator Subscription is
        subscribed or on the operator(s) partner(s) of ONOFF TELECOM by the competent regulatory
        authorities or regulatory groups,
      </li>

      <li>
        in the event of a maintenance operation by ONOFF TELECOM or the operator(s) partner(s) of
        ONOFF TELECOM,
      </li>

      <li>
        in the event of a disturbance or interruption not directly attributable to ONOFF TELECOM,
      </li>

      <li>
        in the event of disruption and/or total or partial unavailability, and/or interruption of
        all or part of the services offered on the networks of the operator with whom the Operator
        Subscription is subscribed,
      </li>

      <li>
        in case of force majeure.
      </li>
    </ul>

    <h3>9. Hosting of communications content</h3>

    <p>
      ONOFF TELECOM undertakes to ensure the security, confidentiality and integrity of
      communications made by the Application in accordance with the applicable legal and regulatory
      obligations.
    </p>

    <p>
      Nevertheless, the contents stored, used, transmitted and received by the User through the
      Application are under his sole responsibility. It is the User's responsibility to ensure that
      he does not violate the legal and regulatory provisions in force, in particular relating to
      the intellectual property rights attached to certain content.
    </p>

    <p>
      The User is solely responsible for the use he makes or intends to make of the functionalities
      of the Application and the User Content. ONOFF TELECOM does not ensure the control or
      promotion of User Content. ONOFF TELECOM assumes no responsibility for any such User Content,
      including, without limitation, media shared through the use of the Application. In addition,
      the User acknowledges and accepts that ONOFF TELECOM does not exercise any a priori control
      over the User Content and that it is not bound by a general obligation to monitor such
      content. Similarly, although it has access to User content, ONOFF TELECOM has no control over
      the sites and external sources to which hypertext links accessible via the Application
      redirect. ONOFF TELECOM is authorized to communicate any User content at the request of any
      administrative or judicial authority.
    </p>

    <p>
      Any User has the possibility to notify ONOFF TELECOM of any User Content that is illegal or
      infringes the User's rights in order to request its withdrawal. The User will, in this case,
      be solely responsible for the complaint he has filed with ONOFF TELECOM. The User acknowledges
      and agrees that any complaint lodged by him may be used in the context of any legal
      proceedings. The User also acknowledges and agrees that any inaccurate, false or misleading
      information may engage his civil and criminal liability.
    </p>

    <p>
      Any complaint or notification falsely accusing User Content of infringing the rights of the
      notifying User or the rights of third parties, by having knowledge of the inaccuracy of this
      statement, makes the notifying User liable to prosecution. The notifying User could thus be
      ordered to pay damages, costs and/or legal costs incurred by the latter, as well as by any
      rightholder who has suffered damage as a result of the trust placed by ONOFF TELECOM in this
      inaccurate declaration. The notifying User, in this case, may also, if necessary, be the
      subject of criminal proceedings.
    </p>

    <p>
      The User declares and guarantees:
    </p>

    <ul>
      <li>
        it must only publish or upload User Content if it has the necessary consents, authorizations
        and licenses.
      </li>
      <li>
        it must only use, publish or include any personal element to a third party within the User
        Content if it has the express permission, consent or permission of that person to that
        effect.
      </li>
    </ul>

    <p>
      By publishing or downloading User Content, the User agrees and agrees to grant ONOFF TELECOM a
      non-exclusive, irrevocable, royalty-free, sublicensable and transferable worldwide license to
      all rights of use, modification, representation, translation, exploitation, edition,
      inclusion, recording, adaptation, reproduction and others. This license is granted for the
      maximum period of protection by intellectual property rights of the User Content published or
      downloaded by the User.
    </p>

    <p>
      The User guarantees ONOFF TELECOM against any claim brought by third parties or other users on
      the grounds that the User Content would constitute a violation of the intellectual or
      industrial property rights claimed by third parties or other Users, ONOFF TELECOM cannot be
      sought or worried about it.
    </p>

    <p>
      Under applicable law, ONOFF TELECOM reserves the right, although it has no obligation to
      monitor User Content, to access such User Content at any time and without prior notification,
      in order to verify compliance with the Terms of Use, to verify any violation or alleged
      violation of the rights of third parties, to verify the liability of Users following
      complaints from individuals and/or requests from authorities, and to take the necessary
      measures, including the removal of User Content, temporary suspension or termination of the
      Services.
    </p>

    <h3>10. Intellectual property</h3>

    <p>
      The Application contains proprietary and confidential information protected by intellectual
      property laws and treaties. ONOFF TELECOM retains the exclusive ownership of the Application
      and the User is prohibited, directly, indirectly or through third parties, from infringing,
      endangering or limiting, by any measure whatsoever, the intellectual property rights that
      ONOFF TELECOM has on the Application. Similarly, any reproduction, representation, adaptation,
      modification, translation, transformation, dissemination, integration, in other material,
      commercial exploitation or not and / or reuse in any way whatsoever of all or part of the
      elements composing the Application is strictly prohibited. ONOFF TELECOM, the trademarks and
      associated logos are trademarks owned by ONOFF TELECOM.
    </p>

    <p>
      The User has, subject to acceptance and compliance with the Terms of Use, a limited,
      non-exclusive, non-transferable, non-transferable, non-transferable right, free of charge, in
      order to download and install the Application on a terminal, such as Smartphone or tablet, and
      to use the Application on a personal basis through his User Account. This right is conferred
      on the User for the entire duration of this Contract and throughout the territory in which
      ONOFF TELECOM markets the Services.
    </p>

    <p>
      The User shall refrain from:
    </p>

    <ul>
      <li>
        license, sell, assign, rent, export, import, distribute or transfer or grant rights to any
        third party in the Application;
      </li>
      <li>
        in any way, modify, copy, decompile, reverse engineer, disassemble all or part of the
        elements composing the Application, or attempt to derive the source code, or create
        derivative works from all or part of the Application or its updates, except where otherwise
        provided by applicable law;
      </li>
      <li>
        directly or indirectly, create a derivative work, search for the source code or protocols of
        the Software of the Application (except to the extent permitted by law);
      </li>
      <li>
        remove, hide or modify trademark or other proprietary notices included in the Application.
      </li>
    </ul>

    <p>
      Any action set out above will be considered a violation of the rights of ONOFF TELECOM. Any
      non-compliant use that the Application may lead to legal proceedings.
    </p>

    <p>
      This section will govern any update provided by ONOFF TELECOM that replaces and/or adds to the
      Application, unless it is accompanied by a separate license.
    </p>

    <h3>11. Personal data</h3>

    <p>
      The collection and use of the User's personal data is defined in the Personal Data Protection
      Policy.
    </p>

    <p>
      Due to the purpose of the Service and the associated confidentiality, registration in the
      universal directory will not be carried out by ONOFF TELECOM. However, the User may request
      his registration in the universal directory by express request addressed to ONOFF TELECOM at
      the following address: support@onoffapp.com. In this case, the User will communicate, under
      his responsibility, his contact details (telephone number and surname, first name or initials
      subject to homonymy, company name, postal address complete or not, e-mail address, profession
      or activity) so that they appear free of charge in the lists of ONOFF TELECOM intended for
      publishers of directories and information services.
    </p>

    <h3>12. Financial conditions</h3>

    <p>
      To the extent that the Application and the Services use the Operator Subscription, the User
      acknowledges and accepts that this use may give rise to billing of the User by his operator as
      part of his Operator Subscription. In particular, the use of the Application through the
      mobile broadband Internet access of the Operator Subscription will be deducted and / or
      invoiced, as the case may be, as the case may be, as part of the Operator Subscription.
      Calls/SMS/MMS made and received from the Application may be counted and/or invoiced, as the
      case may be, as part of the User's Operator Subscription. In case of use of the Services
      outside the territory where the User is established, additional roaming charges may be charged
      by the operator with whom the User has subscribed to his Operator Subscription.
    </p>

    <p>
      For the sake of clarity and transparency with the User, ONOFF TELECOM expressly indicates that
      even incoming calls (i.e. received) from the Application may, in certain cases, be invoiced as
      outgoing calls (i.e. issued) as part of its Operator Subscription by the operator with whom it
      has subscribed to its Operator Subscription and at the rate in force provided by the said
      operator. This system makes it possible to guarantee the User the absolute confidentiality of
      his calls made and received from the Application.
    </p>

    <p>
      All rates applicable to the Services and, in particular, those applicable to Numbers, call
      packages and ONOFF Credits, are mentioned, regularly updated and available via the interface
      of the Application. The Services provided by ONOFF TELECOM are invoiced to the User in
      accordance with the price in force on the day of subscription of the Service concerned.
    </p>

    <p>
      In the event of a change in the rates applicable to a Service subscribed by a User during the
      subscription period selected by the User, ONOFF TELECOM will inform the User at least one
      month before the entry into force of the modified tariff. The User may then terminate the
      Service concerned under the conditions defined in Article 16.
    </p>

    <p>
      The subscription to each Number is subject to the payment of a fixed price that varies
      according to the subscription period selected by the User from among the predetermined
      durations offered to him. The Numbers are divided into several categories, each with its own
      price list appearing in the Application interface.
    </p>

    <p>
      The issuance of telephone calls to fixed or mobile numbers and the sending of SMS may be
      subject to the purchase of ONOFF Credits. The User may purchase ONOFF Credits at the price
      defined in the tariff annex in force on the day of purchase.
    </p>

    <p>
      The User acknowledges and agrees that:
    </p>

    <ul>
      <li>
        ONOFF Credits are counted in one-minute increments and rounded up to the next;
      </li>

      <li>
        ONOFF Credits have a validity period of one hundred and ninety (190) days from the date of
        purchase. At the end of this period, any ONOFF Credit that has not been used will be
        permanently lost. However, any new purchase of ONOFF Credits during this period interrupts
        this period. A new validity period of one hundred and ninety (190) days starts to run again
        from the date on which the User purchased new ONOFF Credits. This new period of validity
        applies to all ONOFF Credits subscribed by the User.
      </li>
    </ul>

    <p>
      Any promotional offers that may be offered by ONOFF TELECOM will be subject to specific
      conditions that will be added to these Terms of Use with regard to the conditions that are
      specifically applicable to them.
    </p>

    <h3>13. Terms of payment – Terms of Refund</h3>

    <p>
      The subscription to each Number is invoiced in advance for the entire subscription period
      chosen by the User.
    </p>

    <p>
      The invoicing and payment of the Services are made, as the case may be, by and with the third
      party with whom the subscription was subscribed and / or directly by and with ONOFF TELECOM.
    </p>

    <p>
      When the payment is made to a third party, it is made according to the contractual conditions
      binding the User to this third party.
    </p>

    <p>
      When the payment is made directly to ONOFF TELECOM, it is made according to one of the payment
      methods offered by ONOFF TELECOM.
    </p>

    <p>
      In case of dispute of the invoiced sums, the User must send his request for reimbursement to
      the company with which he made the payment, that is to say, as the case may be, the third
      party or ONOFF TELECOM. The request for reimbursement must be made, as the case may be, in
      accordance with the terms defined by the conditions of sale of the third party or, when it is
      addressed directly to ONOFF TELECOM under the following conditions.
    </p>

    <p>
      When the payment for the Services has been made in the hands of ONOFF TELECOM, the User will
      have ninety (90) days following the invoice date to contest the sums that have been invoiced
      to him by providing all the necessary justifications. After this period, the User will no
      longer be able to request any refund.
    </p>

    <p>
      ONOFF TELECOM reserves the right to refuse any refund request if it considers (a) that the
      User's request is unfounded, or (b) that the User has not complied with the Terms of Use, or
      (c) that the User has used the Application fraudulently or that his User Account is used
      fraudulently by a third party. In the latter case, the User must exercise his right of refund
      with his bank.
    </p>

    <h3>14. Changes to the Terms of Use</h3>

    <p>
      ONOFF TELECOM informs the User of any modification of the Terms of Use prior to their entry
      into force. If the User refuses that this modification applies to his current Services, he
      must terminate his Contract within one month of the information of the modification, under the
      conditions defined below in Article 15. In the absence of termination within this period, the
      amended Terms of Use shall automatically apply at the end of this period.
    </p>

    <h3>15. Effective date, duration and termination</h3>

    <p>
      The Agreement takes effect on the date of download of the Application by the User and will
      remain in force until the cumulative fulfilment of the following two conditions: (i)
      termination by one of the Parties of all the Services provided to the User and (ii) deletion
      by the User of the Application.
    </p>

    <p>
      The User acknowledges, however, that the license, in connection with the User Content, is
      granted for the maximum period of protection of the intellectual property rights of the User
      Content, and will therefore continue after the end of the Agreement for any reason whatsoever.
    </p>

    <p>
      Unless the Number subscribed by the User has been subscribed for a firm and non-renewable
      period, the subscription to the Number will automatically renew at the end of the period
      initially chosen by the User, unless terminated under the conditions of Article 17. The
      renewal of the subscription will entail invoicing and payment of the corresponding amount.
    </p>

    <p>
      When the Number has been subscribed for a firm and non-renewable period, the User may maintain
      his subscription to the Number manually by connecting to the Application before the expiry of
      the current subscription period. This maintenance of the subscription gives rise to the
      extension of the Contract according to the Conditions of Use and the rates in force on the
      date of the manual renewal of the subscription.
    </p>

    <p>
      The User has a period of fourteen days, from the conclusion of the Contract, to exercise his
      right of withdrawal, without having to justify his decision or to bear other costs than those
      provided for in Articles L. 221-23 to L. 221-25 of the French Consumer Code. The User who
      wishes to exercise his right of withdrawal informs ONOFF TELECOM of his decision to withdraw
      by sending, to the address support@onoffapp.com and before the expiry of the aforementioned
      period, the withdrawal form appearing in annex to the Conditions of Use, or any other
      statement, unambiguous, expressing his will to withdraw. If the subscription of the Services
      has been made via a third party, the User must exercise his right of withdrawal with this
      third party.
    </p>

    <p>
      If the User wishes the execution of the Contract to begin before the end of the aforementioned
      withdrawal period, he must make the express request to ONOFF TELECOM. The User acknowledges
      and accepts that he has been informed of this right and that the use of the Services during
      the duration of the withdrawal period is equivalent to an express request to execute the
      Contract before the end of the withdrawal period.
    </p>

    <p>
      In case of withdrawal, ONOFF TELECOM will refund all payments received, without undue delay
      and, in any case, no later than fourteen days from the day on which it was informed of the
      decision to withdraw. The refund will be made using the same means of payment as that used for
      the initial transaction, at no cost to the User.
    </p>

    <p>
      In the event that the User exercises his right of withdrawal while the execution of the
      Contract has begun at his express request, before the end of the aforementioned withdrawal
      period, he will pay ONOFF TELECOM the amount corresponding to the Services provided until the
      communication of his decision to withdraw. This amount will be proportionate to the total
      price agreed in the Contract. In addition, ONOFF TELECOM may invoice any uses made during the
      withdrawal period.
    </p>

    <p>
      If the User does not expressly request that the execution of the Contract begin before the end
      of the aforementioned withdrawal period, he acknowledges and accepts that the Application is
      unavailable for the entire duration of the withdrawal period (fourteen (14) days).
    </p>

    <h3>16. Termination</h3>

    <p>
      Each Party has the possibility to terminate the Contract at any time. To do this, the User
      simply cancels his number(s) directly in the Application or request the closure of his User
      Account directly in the Application.
    </p>

    <p>
      It is recalled that if the Services have been subscribed through a third party, their
      provision is also subject to the conditions of sale of that third party, in addition to these
      Terms of Use. In this case, the termination of the Services must be carried out directly with
      this third party under the conditions defined by its conditions of sale.
    </p>

    <p>
      The termination of a Number is made by the User in his User Account available on the interface
      of the Application. The termination of the Number will be effective the day after the last day
      of the current subscription period. In the event that the Services have been subscribed via a
      third party, the termination of a Number made from the User Account only entails the cessation
      of payments if the User has informed this third party of its termination in accordance with
      the conditions of sale of this third party.
    </p>

    <p>
      Each Party may also terminate the Contract for fault of the other Party eight (8) days after
      the sending of a formal notice that has remained in vain, this resulting solely from the
      breach of the offending party. It is agreed that the following cases may result in the
      automatic termination of the Contract by ONOFF TELECOM:
    </p>

    <ul>
      <li>
        if the User has violated the Terms of Use;
      </li>

      <li>
        if the information entered by the User in his User Account is inaccurate;
      </li>

      <li>
        if ONOFF TELECOM suspects an illegal or fraudulent use of the Application or contrary to the
        rights of third parties;
      </li>

      <li>
        if ONOFF TELECOM suspects that the User is trying to take unfair advantage of any of ONOFF
        TELECOM's policies, whatever they may be, or to misuse them.
      </li>
    </ul>

    <p>
      ONOFF TELECOM may terminate its relationship with the User, at any time subject to compliance
      with a minimum notice period of one (1) month before the end of the commitment period.
    </p>

    <p>
      If ONOFF TELECOM decides to no longer offer a particular Service, it may terminate the Said
      Service without penalty, subject to compliance with a minimum notice of one month.
    </p>

    <p>
      Any termination of ONOFF TELECOM will be notified to the User by e-mail.
    </p>

    <p>
      The User understands and agrees not to use a Number under the conditions defined in the
      General Conditions of Use entails the automatic termination of the Number as well as data
      relating to the use of the Number (for example: SMS, call log, voice messages, etc.).
    </p>

    <p>
      On the effective date of termination of the Contract, it will have the following consequences:
    </p>

    <ul>
      <li>
        All licenses and rights to use the Application will terminate;
      </li>

      <li>
        The User must stop using the Application;
      </li>

      <li>
        The User shall remove the Application from all hard drives, networks and storage media, and
        destroy all copies in his possession, or under his control;
      </li>

      <li>
        ONOFF TELECOM may block access to the User Account and/ or the Application;
      </li>

      <li>
        ONOFF TELECOM may delete the data associated with the User Account.
      </li>
    </ul>

    <p>
      Termination of a particular Service will have the same consequences as those set forth above,
      which will be limited to the terminated Service only.
    </p>

    <h3>17. Portability</h3>

    <p>
      The User who wishes to transfer the use of the Number to another mobile operator must request
      the portability of his Number from his new operator. Number portability is possible, subject
      to technical eligibility. The User's attention is drawn to the fact that an outgoing request
      for portability is equivalent to a request for termination of the Number and entails, at the
      end of the corresponding procedure, the automatic termination of the Services associated with
      that Number, without prejudice to the possible duration of the User's firm commitment under
      the Services. ONOFF TELECOM remains in any case foreign to the contractual relations between
      the User and the new operator. ONOFF TELECOM cannot be held liable for fault, non-performance,
      failures or malfunctions attributable to the User or the new operator and which would have the
      effect of delaying, disrupting or preventing this porting. In these cases, ONOFF TELECOM
      cannot be held liable because of the interruption of service related to the porting of the
      Number to the new operator.
    </p>

    <p>
      When subscribing to the Services with ONOFF TELECOM, the User may request the takeover by
      ONOFF TELECOM of its current mobile number. Inbound portability of a mobile number is
      possible, subject to technical eligibility. The mobile number in question must be associated
      with an active telephone line until the number is transferred. When subscribing to the
      Services, the User must transmit the number to be kept to ONOFF TELECOM, the desired porting
      date as well as all the data required by the former operator and associated with said number.
      The User mandates ONOFF TELECOM in order to carry out all the acts necessary for the
      implementation of his request for portability and in particular to terminate in his name and
      on his behalf the contract he holds with his former operator relating to this number. This
      termination will take effect with the effective transfer of the User's number to ONOFF
      TELECOM.
    </p>

    <p>
      In this context, ONOFF TELECOM informs the User of the eligibility conditions, as well as the
      consequences of his request: the request for number portability is equivalent to a request for
      termination of the User's contract with its previous operator, without prejudice to the
      contractual stipulations relating to the durations of engagement. The User remains bound by
      the obligations that bind him to his previous operator, in particular any payment that may
      remain. The effective porting of the number entails the termination of the contract that binds
      the User with his previous operator. Portability does not entail the transfer of services from
      which the User benefited under the contract previously signed with its previous operator. As
      such, the User's attention is drawn to the fact that the Services require the existence of an
      Operator Subscription in force. The User is therefore clearly informed that if his request for
      portability leads to the termination of the Operator Subscription, he will not be able to use
      the Services.
    </p>

    <p>
      ONOFF TELECOM may refuse to comply with a request for portability in the following cases:
    </p>

    <ul>
      <li>
        incapacity of the applicant;
      </li>

      <li>
        Portability request that is incomplete or contains incorrect information;
      </li>

      <li>
        non-compliance with the management rules of the national numbering plan: the request for
        preservation must respect the correspondence between the number that is the subject of the
        application and the geographical location of the applicant;
      </li>

      <li>
        mobile number, subject of the portability request, inactive on the day of porting;
      </li>

      <li>
        mobile number, subject of the portability request, already the subject of a portability
        request not yet executed.
      </li>
    </ul>

    <h3>18. Suspension</h3>

    <p>
      Without prejudice to the possibility of terminating the Contract, ONOFF TELECOM may suspend
      the Services or access to the Application of one of the Users on simple written information of
      the User concerned in the following cases:
    </p>

    <ul>
      <li>
        if the User has violated the Terms of Use;
      </li>

      <li>
        if the information provided for the User in his User Account is inaccurate, incomplete or if
        the User's mobile number provided by the operator with whom the Operator Subscription was
        subscribed is no longer valid;
      </li>

      <li>
        if the conditions of territoriality required for the assignment of the Number to the User
        are no longer met
      </li>

      <li>
        if ONOFF TELECOM suspects illegal or fraudulent or abnormal use of the Application or
        contrary to the rights of third parties;
      </li>

      <li>
        if ONOFF TELECOM suspects that the User is trying to take unfair advantage of onoff
        telecom's refund policy or any other policies, or to misuse them;
      </li>

      <li>
        if ONOFF TELECOM suspects that the User Account is the subject of fraudulent use by a third
        party.
      </li>
    </ul>

    <p>
      ONOFF TELECOM is also likely to interrupt the use of the Application under certain conditions
      including, but not limited to, the impossibility of providing the Service, problems of
      external origin that make the implementation of the Service difficult or imprudent,
      technological progress, the need to modify the Application or the Services.
    </p>

    <h3>19. Warranty and Liability</h3>

    <p>
      ONOFF TELECOM undertakes to provide all the necessary competence and care for the provision of
      the Services and to implement all the necessary provisions in order to ensure the Services
      permanently and continuously. As such, ONOFF TELECOM has only an obligation of means.
    </p>

    <p>
      With regard to the relevant and applicable legal provisions, the Application is provided "as
      is" and will be used at the User's own risk, without any guarantee. In this sense, the User is
      solely responsible for the preservation, security and integrity of data, hardware and
      software, as well as the confidentiality of his passwords and User Account identifiers and
      others in the context of the use of the Services.
    </p>

    <p>
      ONOFF TELECOM assumes no responsibility to any third party or to the User regarding the
      contents, the latter being solely responsible for the User Content submitted, created,
      displayed or published through the Application.
    </p>

    <p>
      ONOFF TELECOM makes its best efforts to ensure the confidentiality of the information and
      content published through the Application.
    </p>

    <p>
      ONOFF TELECOM does not guarantee that the Application will always be available, accessible,
      uninterrupted, reliable, and error-free. In addition, no warranty is offered regarding
      internet connections or transmissions, or the quality of calls made through the Application.
    </p>

    <p>
      Should a third party file a complaint against ONOFF TELECOM in connection with, or as a result
      of (i) any breach by the User of the Terms of Use, (ii) any breach by the User of any
      applicable law or regulation, (iii) any violation by the User of the rights of third parties
      (including intellectual property rights), (iv) its User Content or (v) its complaint regarding
      any User Content, the User warrants to ONOFF TELECOM any damages, liabilities, losses,
      convictions, costs and expenses (including legal fees and other reasonable costs) that may
      result therefrom.
    </p>

    <p>
      Subject to the foregoing and in accordance with applicable law, ONOFF TELECOM shall not be
      liable to the User, whether or not ONOFF TELECOM has been informed of the possibility of such
      damages or losses, for:
    </p>

    <ul>
      <li>
        indirect damage and in particular any damage, loss, or corruption of data;
      </li>

      <li>
        damage resulting from the acts of third parties or cases of force majeure;
      </li>

      <li>
        any claim, damage or loss arising out of or related to:
        <ul>
          <li>
            the User's inability to use all or part of the Application or its functionality;
          </li>
          <li>
            the User's inability to make the necessary arrangements to contact the emergency
            services;
          </li>
          <li>
            the User's inability to provide accurate information about the physical location to an
            emergency department;
          </li>
          <li>
            the conduct of third-party emergency service agents and call centers with which the User
            is connected.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      The Services are in no way a substitute for the primary mobile telephony service provided by
      the operator with whom an Operator Subscription has been subscribed, so the User acknowledges
      and accepts that the Numbers do not allow calls to be made to all classes of numbers.
      Consequently, calls that cannot be made from the Application (i.e. in particular calls to
      special numbers, international numbers and emergency numbers) must be made directly from the
      User's telephone. ONOFF TELECOM reserves the right to allow Users to make calls to additional
      number classes after the launch of the Services.
    </p>

    <p>
      In addition to the foregoing, ONOFF TELECOM cannot be held liable to the User, whether or not
      ONOFF TELECOM has been informed of the possibility of such damages or losses, for:
    </p>

    <ul>
      <li>
        any indirect damage, and in particular, any loss of income, contracts, real or expected
        profits, luck, brand image or reputation, customers or turnover;
      </li>
      <li>
        any damage or corruption of data;
      </li>
      <li>
        any claim, damage or loss arising out of or related to:
      </li>
      <li>
        the User's inability to use all or part of the Application or its functionality;
      </li>
      <li>
        the User's inability to make the necessary arrangements to contact the emergency services
      </li>
      <li>
        the User's inability to provide accurate information about the physical location to an
        emergency department;
      </li>
      <li>
        the conduct of third-party emergency service agents and call centers with which the User is
        connected.
      </li>
      <li>
        any damage in excess of an amount, all counts of damages combined, corresponding to the
        total amount paid by the User for the Services during the twelve (12) month period
        immediately preceding the date of occurrence of the event at the origin of the request in
        question.
      </li>
    </ul>

    <p>
      To the extent that the User demonstrates that he has suffered direct damage, the liability of
      ONOFF TELECOM will be expressly limited to one hundred percent (100%) of the sums actually
      received by ONOFF TELECOM under the Contract during the last six (6) months preceding the
      occurrence of the said damage.
    </p>

    <h3>20. Force majeure</h3>

    <p>
      ONOFF TELECOM cannot be held liable in the event that the non-performance results from a case
      of force majeure.
    </p>

    <p>
      Are considered as cases of force majeure, as long as they are not the work of ONOFF TELECOM
      and that they are beyond its control, exceptional bad weather, natural disasters, fires and
      floods, lightning, electronic power surges, attacks, internal or external strikes, internal or
      external failures or failures such as failures related to the telephone network, the networks
      of ONOFF TELECOM's service providers, and all those emanating from the mobile phone operator
      with which the User has subscribed his Operator Subscription, and in general any irresistible
      and unforeseeable event that does not allow the proper functioning of the Application.
    </p>

    <h3>21. Dispute resolution – applicable law</h3>

    <p>
      ONOFF provides Users with a support service accessible by e-mail to the following e-mail
      address: support@onoffapp.com. Any complaint must be made by sending an e-mail to the
      following e-mail address: support@onoffapp.com. ONOFF TELECOM undertakes to provide a response
      to any dispute or request for reimbursement within thirty(30) working days from the date of
      receipt thereof. In the event of non-compliance with this deadline, ONOFF TELECOM undertakes,
      upon request for compensation justifying the non-respect of this commitment to the following
      address: support@onoffapp.com, to award the affected User compensation equivalent to one (1)
      month of subscription to the Service concerned.
    </p>

    <p>
      In the event of a dispute arising in particular from the interpretation or execution of the
      Contract, the parties will endeavor to find an amicable solution. Any User established in
      France is informed that he can refer the matter free of charge to the Mediator of electronic
      communications on his website: www.mediateur-telecom.fr of any dispute with ONOFF TELECOM.
    </p>

    <p>
      In any case, the User may refer the matter to any competent court in the event of a dispute
      arising from the interpretation or execution of the Contract.
    </p>

    <p>
      The Terms of Use and the terms of provision of the Services are subject to French law.
    </p>

    <h2>TEMPLATE WITHDRAWAL FORM</h2>

    <p>Please complete and return this form only if you wish to withdraw from the Agreement.</p>
    <p>Attention: ONOFF TELECOM</p>
    <p>Email : support@onoffapp.com</p>
    <br />
    <p>
      I/we (1) hereby notify you (1) (*) of the contract for the subscription of services below:
      (2)
    </p>
    <p>Ordered on: (2)</p>
    <p>Name of consumer(s): (2)</p>
    <p>E-mail address of the consumer(s): (2)</p>
    <p>Onoff phone numbers: (2)</p>
    <p>Signature of the consumer(s) (only in case of notification of this form on paper):</p>
    <p>Date : (2)</p>
    <p>(1) Strike out what does not apply.</p>
    <p>(2) To be completed by the user.</p>
  </>
);

export default EnglishText;
