/* eslint-disable react/jsx-no-literals,react/no-unescaped-entities,max-len */
import React from 'react';

const FrenchText: React.FC = () => (
  <>
    <h2 className="section-title center-text">
      CONDITIONS GÉNÉRALES D’UTILISATION D’ONOFF TELECOM
    </h2>

    <p>
      Avant d’accéder aux Services proposés par ONOFF TELECOM, l’Utilisateur est invité à lire
      attentivement les présentes Conditions d’Utilisation. Elles définissent les modalités de mise
      à disposition des Services et les conditions d’utilisation de l’Application.
    </p>

    <p>
      L’auteur de l’offre est la société ONOFF TELECOM, société par actions simplifiée, immatriculée
      au Registre du Commerce et des Sociétés de Paris sous le numéro 832 235 253 et ayant son siège
      social au 26 boulevard de Bonne Nouvelle 75010, Paris, France, (« ONOFF TELECOM ») a mis au
      point une Application dénommée ONOFF TELECOM proposant des services secondaires de gestion de
      contacts et de communications.
    </p>

    <p>
      L’accès aux Services par l’Utilisateur est conditionné à l’acceptation préalable des
      Conditions d’Utilisation
    </p>

    <p>
      Les Conditions d’Utilisation ne pourront être valablement acceptées que par un utilisateur (a)
      légalement autorisé à utiliser l’Application dans le pays où il se trouve et (b) en âge légal
      pour conclure un contrat juridiquement contraignant avec ONOFF TELECOM.
    </p>

    <h3>1. Définitions</h3>

    <p>
      Dans les présentes Conditions d’Utilisation, les termes suivants ont, sauf précision
      contraire, la signification suivante :
    </p>

    <p>
      <strong>Abonnement Opérateur</strong>
      {' '}
      : désigne le contrat de services de téléphonie mobile et
      d’Internet haut débit mobile souscrit par l’Utilisateur auprès d’un opérateur tiers
      préalablement à l’utilisation des Services et indépendamment d’ONOFF TELECOM.
    </p>

    <p>
      <strong>Application</strong>
      {' '}
      : désigne toute application (dont le logiciel dénommé ONOFF),
      développée et exploitée par ONOFF TELECOM, téléchargeable sur les plateformes de distribution
      d’applications et permettant à l'Utilisateur de bénéficier des Services. Le terme Application
      désigne également la solution permettant l’utilisation de Numéros à travers des applications
      appartenant à des tiers (Microsoft Teams ou Zoom).
    </p>

    <p>
      <strong>Compte Utilisateur</strong>
      {' '}
      : désigne le compte personnel créé par l’Utilisateur afin
      d’utiliser l’Application.
    </p>

    <p>
      <strong>Conditions d’Utilisation</strong>
      {' '}
      : désigne les présentes conditions générales
      d’utilisation régissant, avec la Politique de Protection des Données Personnelles, les
      relations contractuelles relatives à la fourniture des Services par ONOFF TELECOM à
      l’Utilisateur.
    </p>

    <p>
      <strong>Contenu Utilisateur</strong>
      {' '}
      : désigne les contenus publiés par l’Utilisateur via
      l’Application.
    </p>

    <p>
      <strong>Crédits ONOFF</strong>
      {' '}
      : désigne les crédits permettant d’acheter des Services, et
      notamment des Numéros, émettre des appels téléphoniques et/ou envoyer des SMS.
    </p>

    <p>
      <strong>Numéro(s)</strong>
      {' '}
      : désigne le(s) numéro(s) de téléphonie mobile du plan national de
      numérotation affecté(s) à l’Utilisateur par ONOFF TELECOM et permettant l’identification de
      l’Utilisateur via l’Application.
    </p>

    <p>
      <strong>Politique de Protection des Données Personnelles</strong>
      {' '}
      : désigne la politique de
      protection des données personnelles définie par ONOFF TELECOM et disponible à l’adresse [●].
    </p>

    <p>
      <strong>Service(s)</strong>
      {' '}
      : désigne l’ensemble des services secondaires de gestion de
      contacts et de communications payants ou gratuits proposés par ONOFF TELECOM et accessibles à
      partir d’un accès mobile par l’Utilisateur via l’Application conformément aux Conditions
      d’Utilisation.
    </p>

    <p>
      <strong>Site Web</strong>
      {' '}
      : désigne le site accessible à l’adresse
      {' '}
      <a href="www.onoff.app">
        www.onoff.app
      </a>
      , exploité par
      ONOFF TELECOM.
    </p>

    <p>
      <strong>Utilisateur</strong>
      {' '}
      : désigne le titulaire d’un Compte Utilisateur et le détenteur
      d’une licence d’utilisation de l’Application conformément aux Conditions d’Utilisation.
    </p>

    <h3>2. Objet des Conditions d’Utilisation</h3>

    <p>
      Les Conditions d’Utilisation s’appliquent aux Services commercialisés et exploités par ONOFF
      TELECOM et ont pour objet de définir les conditions pratiques et financières aux termes
      desquelles l'Utilisateur va pouvoir (i) créer son Compte Utilisateur, (ii) se voir concéder
      une licence d’utilisation non exclusive de l’Application et (iii) pouvoir utiliser
      l’Application et les Services.
    </p>

    <p>
      ONOFF TELECOM peut, de plein droit, modifier ses offres de Services et/ou porter des
      modifications dans les Conditions d’Utilisation sans autre formalité que d'en informer
      préalablement l’Utilisateur par tout moyen à sa convenance. Dans l’hypothèse où l’Utilisateur
      ne souhaiterait pas accepter l’application des nouvelles Conditions d’Utilisation, il pourra
      notifier son souhait de mettre fin au Contrat intervenu avec ONOFF TELECOM. Le fait pour
      l’Utilisateur de ne pas mettre fin au Contrat emportera son acceptation sans réserve de la
      nouvelle version des Conditions d’Utilisation.
    </p>

    <p>
      L’accès aux Services est conditionné à l’acceptation préalable des Conditions d’Utilisation.
      L’Utilisateur acceptera les Conditions d’Utilisation (i) en cliquant pour accepter les
      Conditions d’Utilisation ou (ii) en créant son Compte Utilisateur.
    </p>

    <p>
      Si l’accès à l’Application et/ ou aux Services s’est fait par l’intermédiaire d’un tiers, des
      conditions d’utilisation distinctes présentées par ce tiers peuvent également s’appliquer à
      l’accès aux Services par l’Utilisateur. Ces conditions seront également applicables à la
      fourniture des Services et devront être respectées par l’Utilisateur.
    </p>

    <h3>3. Accès et utilisation de l’Application et des Services</h3>

    <p>
      La souscription aux Services peut être effectuée, selon le cas, directement auprès d’ONOFF
      TELECOM ou auprès d’un tiers, tel qu’une plateforme de distribution d’applications.
    </p>

    <p>
      L’accès et l’utilisation des Services requièrent (i) l’utilisation de la carte SIM associée à
      l’Abonnement Opérateur, (ii) un terminal permettant l’utilisation des Services (Smartphone,
      PDA, tablette ou équivalent) et disposant d’un système d’exploitation de version récente et
      (iii) un accès au réseau Internet.
    </p>

    <p>
      Le fonctionnement de l’Application et des Services requiert la souscription préalable par
      l’Utilisateur - à ses frais - d’un Abonnement Opérateur comprenant (i) la possibilité
      d’émettre et de recevoir des appels téléphoniques et/ ou des SMS et (ii) un accès Internet
      mobile haut débit ainsi que le crédit téléphonique correspondant.
    </p>

    <p>
      La souscription et le maintien en vigueur de l’Abonnement Opérateur, indispensables à
      l’utilisation de l’Application, relèvent de la seule responsabilité de l’Utilisateur.
      L’Utilisateur devra s’équiper d’un terminal permettant l’utilisation de l’Application
      (Smartphone, PDA, tablette ou équivalent) et disposant d’un système d’exploitation de version
      récente et mis régulièrement à jour. L’Utilisateur est seul responsable du bon fonctionnement
      de ses terminaux.
    </p>

    <p>
      L’Utilisateur reconnait que l'utilisation effective de l’Application dépend de la
      disponibilité des services de communications et d’Internet haut débit de l'Abonnement
      Opérateur qu’il a souscrit. En outre, des limites d’utilisation et des restrictions
      géographiques spécifiques à l’Application peuvent varier. Ainsi, il est conseillé à
      l’Utilisateur de consulter régulièrement le Site Web afin de connaitre plus en détail les
      restrictions d’utilisation et les modifications récentes apportées à l’Application.
    </p>

    <p>
      Une fois l’Application installée sur son terminal, l’Utilisateur pourra accéder à son Compte
      Utilisateur avec l’identifiant et le mot de passe qu’il aura défini lors de la création du
      Compte Utilisateur. Son mot de passe est personnel et confidentiel et ne devra être partagé
      avec aucun tiers afin notamment d’empêcher toute utilisation non autorisée du Compte
      Utilisateur. L’Utilisateur est seul responsable de la confidentialité de son mot de passe.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de suspendre, refuser ou d’annuler la souscription au
      Contrat ou de suspendre l’exécution du Contrat si l’une des conditions suivantes n’est pas
      respectée (i) le règlement à échéance de toutes les dettes contractées par l’Utilisateur
      auprès d’ONOFF TELECOM au titre d’un autre contrat, (ii) la fourniture de tous les
      justificatifs demandés par ONOFF TELECOM, (iii) l’accord donné par les centres de paiement
      bancaire concernés et (iv) les déclarations exactes de l’Utilisateur. Dans ce cas, la demande
      de souscription sera automatiquement refusée ou annulée et l’Utilisateur prévenu par mail.
    </p>

    <p>
      En cours de Contrat, ONOFF TELECOM se réserve le droit de procéder et/ou faire procéder à
      toute investigation complémentaire notamment sur les éléments transmis par l’Utilisateur et,
      en cas de doute raisonnable sur l’utilisateur du Service ou les documents qui ont été transmis
      à l’appui de la souscription, de refuser ou d’annuler toute souscription au Contrat. Dans ce
      cas, la demande de souscription sera automatiquement refusée ou annulée et l’Utilisateur
      prévenu par mail.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de supprimer à tout moment un Compte Utilisateur qui ne
      respecterait pas les dispositions légales applicables, les droits des tiers ou qui ne serait
      pas conforme aux Conditions d’Utilisation. Ainsi en serait-il par exemple d’un Compte
      Utilisateur créé sur la base d’un faux nom de famille ou d’une adresse e-mail invalide.
    </p>

    <h3>4. Description des Services</h3>

    <p>
      Une fois les étapes d’inscription réalisées, l’Utilisateur pourra obtenir auprès d’ONOFF
      TELECOM un ou plusieurs Numéros et/ou souscrire à un ou plusieurs forfaits d'appel et
      bénéficier des fonctionnalités associées. Selon la réglementation applicable aux Numéros
      souscrits, ONOFF TELECOM peut être amenée à demander des informations complémentaires à celles
      collectées lors de la création du Compte Utilisateur relatives à l’identité ou à la
      localisation de l’Utilisateur.
    </p>

    <p>
      L’affectation d’un Numéro donne accès à l’ensemble des Services liés. Les Numéros permettent
      d’émettre et de recevoir des appels téléphoniques et/ ou le cas échéant des SMS et/ ou des MMS
      vers et de toute personne disposant d’un numéro de téléphone sous réserve des limitations
      énoncées au paragraphe suivant concernant les appels vers certaines classes de numéros.
    </p>

    <p>
      Les Services ne se substituant en aucun cas au service primaire de téléphonie mobile fourni
      par l’opérateur auprès duquel l’Utilisateur a souscrit son Abonnement Opérateur, l’Utilisateur
      reconnait et accepte que les Numéros ne permettent pas d’émettre des appels vers tous les
      numéros. En conséquence, les appels ne pouvant être émis depuis l’Application (c’est-à-dire
      notamment mais non exhaustivement les appels vers les numéros spéciaux et les numéros
      d’urgence) devront être émis directement depuis le numéro associé à l’Abonnement Opérateur de
      l’Utilisateur.
    </p>

    <h3>5. Restrictions d’utilisation de l’Application</h3>

    <p>
      L’Utilisateur devra faire une utilisation normale de l’Application et des Services associés et
      reconnaît qu’ONOFF TELECOM pourra mettre en place les mesures visant à prévenir tout
      détournement manifeste de l’objet des Services.
    </p>

    <p>
      ONOFF TELECOM évaluera l’utilisation normale des Services par comparaison aux utilisations des
      autres Utilisateurs. La consommation sera considérée anormale, en ce qu’elle sera considérée
      constituer un détournement de l’objet des Services, si elle s’éloigne substantiellement de la
      consommation moyenne des Utilisateurs.
    </p>

    <p>
      L’Utilisateur est en outre informé que les utilisations suivantes, sans que cette liste ne
      puisse être considérée comme exhaustive, sont considérées comme anormales :
    </p>

    <ul>
      <li>
        l’émission de plus de quinze (15) appels en moins de trois (3) minutes depuis un Compte
        Utilisateur,
      </li>

      <li>
        l’émission d’appels de plus de 2 heures depuis un compte Utilisateur,
      </li>

      <li>
        l’utilisation de l’Application et/ ou des Services pour la réalisation d’une campagne
        marketing à des fins commerciales, le spamming, la fourniture de services automatisés,
      </li>

      <li>
        la revente ou la tentative de revente des Services à des tiers,
      </li>

      <li>
        l’utilisation de Numéros d’un pays donné sans pouvoir justifier de lien stable impliquant
        une présence fréquente et significative dans ledit pays.
      </li>
    </ul>

    <p>
      Si ONOFF TELECOM détecte une utilisation anormale des Services, elle notifiera à l’Utilisateur
      le caractère anormal de l’utilisation des Services. ONOFF TELECOM pourra alors suspendre
      immédiatement les Services de façon temporaire ou permanente, sans que sa responsabilité
      puisse être engagée au titre de cette suspension.
    </p>

    <p>
      L’Utilisateur devra utiliser l’Application dans le respect de la loi et de la réglementation
      applicables
    </p>

    <p>
      L’Utilisateur ne devra en aucun cas faire une utilisation non autorisée de l’Application ou
      des Services, c’est-à-dire contraire à ce qui précède. L’Utilisateur est en outre informé que
      les utilisations suivantes, sans que cette liste ne puisse être considérée comme exhaustive,
      sont considérées comme non autorisées :
    </p>

    <ul>
      <li>
        porter atteinte ou tenter de porter atteinte à l’Application, et notamment :
        <ul>
          <li>
            utiliser un procédé conçu pour déformer, altérer, modifier, désassembler, effacer
            l’Application, les protocoles et les communications, tels que notamment, des virus,
            chevaux de Troie, un vers, bombes logiques, etc. ;
          </li>

          <li>
            altérer ou tenter d’altérer l’Application, sa fonctionnalité ou sa disponibilité ;
          </li>

          <li>
            contourner, désactiver ou perturber les fonctions liées à la sécurité de l’Application
            et à son intégrité (ou celle de tout équipement électronique quel qu’il soit), ou les
            fonctions qui empêchent ou limitent l’utilisation du contenu du Logiciel ;
          </li>

          <li>
            utiliser un système automatisé afin d’accéder à l’Application.
          </li>
        </ul>
      </li>

      <li>
        télécharger et/ou partager des contenus inappropriés (illégaux, portant atteinte à la
        dignité humaine, à caractère sexuel, diffamatoires, injurieux, racistes ou tout autre
        contenu pouvant engager la responsabilité civile ou pénale de la personne qui le publie) ;
      </li>

      <li>
        se livrer à des activités portant atteinte à la vie privée d’autres personnes, notamment en
        usurpant l’identité d’une autre personne ;
      </li>

      <li>
        modifier, intercepter ou surveiller toute communication qui ne lui est pas destinée ;
      </li>

      <li>
        récolter des informations depuis l’Application qui permettraient une identification
        personnelle y compris les noms de comptes ;
      </li>

      <li>
        utiliser l’Application afin d’envoyer, ou d’aider d’autres personnes à envoyer, des messages
        indésirables. Sont considérés comme indésirables l’ensemble des messages et des demandes de
        contact non sollicitées (« spam », « SPIT ou Spam over Internet Telephony », « courrier
        indésirable » ou des contenus trompeurs et mensongers) et envoyées massivement, qu’ils
        soient sous la forme d’e-mails, d’appels, de messages instantanés ou autres ;
      </li>

      <li>
        utiliser l’Application à des fins d’hameçonnage (ou phishing), d’usurpation d’identité ou de
        dévoiement ;
      </li>

      <li>
        utiliser l’Application afin d’embarrasser, de menacer, de harceler ou d’envahir la vie
        privée d’un tiers, ou exposer un tiers à des contenus choquants, indécents ou dangereux ;
      </li>

      <li>
        Porter atteinte aux droits de propriété intellectuelle d’ONOFF TELECOM, d’un autre
        Utilisateur, ou d’un tiers, et notamment :
        <ul>
          <li>
            utiliser de quelque manière que ce soit tout contenu soumis à des droits de propriété
            n’appartenant pas à l’Utilisateur , à moins que celui-ci ne détienne une licence ou la
            permission expresse du propriétaire des droits ;
          </li>

          <li>
            revendre ou commercialiser l’Application, alors que celle-ci est destinée à une
            utilisation personnelle de l’Utilisateur.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      Par ailleurs, il est expressément rappelé à l’Utilisateur que, conformément à la décision de
      l’ARCEP n° 2018-0881 modifiée établissant le plan national de numérotation et ses règles de
      gestion, tout Numéro ne peut être utilisé en tant que « numéro secondaire » pour la fourniture
      de services téléphoniques ou de messagerie par SMS ou MMS, dont le souscripteur est joignable
      sans aucune restriction par tout utilisateur d’un service téléphonique au public ou de SMS ou
      MMS que dès lors que ces services ne sont utilisables qu’à partir d’un accès mobile.
    </p>

    <p>
      Il est également rappelé que ce numéro « secondaire » peut être fourni par un opérateur
      différent de celui qui fournit l’accès mobile à l’Utilisateur.
    </p>

    <p>
      Il est enfin rappelé qu’un Numéro ne peut être affecté qu’à un Utilisateur résidant
      habituellement ou temporairement, ou justifiant de liens stables impliquant une présence
      fréquente et significative, dans le territoire correspondant à ce Numéro.
    </p>

    <p>
      Si ONOFF TELECOM détecte une utilisation non autorisée de l’Application ou des Services, elle
      le notifiera à l’Utilisateur. ONOFF TELECOM pourra alors suspendre immédiatement les Services
      de façon temporaire ou permanente et/ou résilier le contrat intervenu avec l’Utilisateur, sans
      que sa responsabilité ne puisse être engagée au titre de cette suspension ou de cette
      résiliation.
    </p>

    <h3>6. Mise à jour et maintenance</h3>

    <p>
      ONOFF TELECOM pourra à tout moment faire des mises à jour de l’Application.
    </p>

    <p>
      Ces mises à jour pourront avoir pour objet de maintenir la compatibilité logicielle, proposer
      de nouvelles fonctionnalités ou versions de l’Application, fournir des mises à jour de
      sécurité ou des corrections de bugs, etc. ONOFF TELECOM se réserve le droit de vérifier les
      versions de l’Application et télécharger les modifications de configuration et les mises à
      jour de l’Application. ONOFF TELECOM ne garantit pas que l’Application mise à jour soit
      toujours compatible avec les versions plus anciennes de l’Application. ONOFF TELECOM ne
      garantit pas non plus de continuer à prendre en charge une version du système d’exploitation
      ou de l’appareil pour lequel l’Utilisateur dispose d’une Licence.
    </p>

    <p>
      L’Utilisateur reconnaît qu’il devra en permanence utiliser une version à jour de
      l’Application.
    </p>

    <p>
      ONOFF TELECOM est susceptible de modifier l’Application à tout moment et à cet effet de
      réaliser des opérations de maintenance ou de mise à niveau de l’Application ou de
      l’infrastructure sous-jacente permettant l’utilisation de l’Application. ONOFF TELECOM pourra
      dans ce cadre suspendre temporairement ou limiter l’utilisation de tout ou partie de
      l’Application ou des Services. Sauf en cas d’urgence, les interruptions temporaires seront,
      dans la mesure du possible, notifiées sur le Site Web avant qu’elles n’interviennent. ONOFF
      TELECOM ne sera en aucun cas redevable de dommages et intérêts envers l’Utilisateur en cas de
      suspension ou de limitation de l’utilisation de l’Application en raison de la conduite
      d’opérations de maintenance.
    </p>

    <h3>7. Conditions d’utilisation des Services</h3>

    <p>
      En se voyant affecter un Numéro, l’Utilisateur accepte ce qui suit :
    </p>

    <ul>
      <li>
        l’Utilisateur ne dispose d’aucun droit de propriété sur le(s)Numéro(s), pas plus que d’un
        droit à la conservation ou au maintien définitif du/des Numéro(s) ;
      </li>

      <li>
        l’Utilisateur devra utiliser l’Application dans le respect de la loi et de la réglementation
        applicables et des Conditions d’Utilisation ;
      </li>

      <li>
        l’Utilisateur devra se conformer en permanence aux règles applicables en matière de
        numérotation et aux exigences particulières d’affectation des Numéros ainsi qu’à toutes les
        instructions qu’ONOFF TELECOM pourrait lui adresser en ce qui concerne le(s) Numéro(s) ;
      </li>

      <li>
        l’Utilisateur dispose d’un droit d’utilisation révocable du Numéro, excluant notamment sa
        cession, sa sous-location, son transfert, sa portabilité ou plus généralement tout acte de
        disposition ou de sûreté.
      </li>
    </ul>

    <p>
      ONOFF TELECOM se réserve le droit de modifier les conditions d’accès aux Numéros et de
      fourniture des Services, en particulier en cas de changement des dispositions légales et
      réglementaires applicables ou pour prendre en compte toute décision administrative ou
      judiciaire qui serait applicable aux modalités de fourniture des Numéros et des Services
      associés.
    </p>

    <p>
      L’Utilisateur reconnait et accepte que l’émission et la réception d’appels téléphoniques et
      des SMS/MMS peuvent requérir l’utilisation de la carte SIM associée à l’Abonnement Opérateur
      et des crédits téléphonique et/ou data correspondants. Les appels/SMS/MMS émis et reçus depuis
      l’Application sont décomptés et/ou facturés, selon le cas, dans le cadre de l’Abonnement
      Opérateur de l’Utilisateur.
    </p>

    <p>
      L’utilisation de l’Application et par conséquent l’accès aux Services nécessite un accès au
      réseau Internet. Les conditions d’accès au réseau Internet sont définies par le fournisseur
      d’accès de l’Utilisateur dans le cadre de l’Abonnement Opérateur.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de suspendre à tout moment l’accès aux Services à tout
      Utilisateur qui utiliserait l’Application en violation des dispositions légales et
      réglementaires applicables, des droits des tiers ou des Conditions d’Utilisation.
    </p>

    <h3>8. Qualité du Service</h3>

    <p>
      ONOFF TELECOM applique les mesures de sécurité nécessaires à la prévention des incidents et
      met en œuvre les moyens lui permettant de réagir à un incident pour limiter l’indisponibilité
      des Services et l’impact sur les données des Utilisateurs.
    </p>

    <p>
      ONOFF TELECOM s’engage à atteindre, en zone couverte, les niveaux de service suivants :
    </p>

    <ul>
      <li>
        une disponibilité des Services dans un délai maximum de six (6) heures à compter de la
        création du Compte Utilisateur et de l’affectation des Services à l’Utilisateur,
      </li>

      <li>
        une disponibilité mensuelle des Services au moins égale à 90 %, la disponibilité du Service
        s’entendant de la possibilité matérielle d’émettre ou de recevoir une communication,
      </li>

      <li>
        un rétablissement des Services dans un délai maximum de quarante-huit (48) heures à compter
        de la notification de l’interruption des Services auprès du service d’assistance client (le
        « Support ») d’ONOFF TELECOM accessible par le Site Web. L’interruption du Service s’entend
        de l’impossibilité matérielle d’émettre ou de recevoir une communication.
      </li>
    </ul>

    <p>
      Les niveaux de service s’appliquent lorsque les conditions suivantes sont cumulativement
      remplies : (i) l’Utilisateur se situe dans une zone habituellement couverte par le Service et
      son Abonnement Opérateur et (ii) dispose d’un terminal compatible avec l’Application, allumé
      et en parfait état de fonctionnement.
    </p>

    <p>
      Dans l’hypothèse où ONOFF TELECOM n’atteindrait pas l’un des niveaux de service susmentionnés,
      l'Utilisateur disposera d’un délai d’un (1) mois pour adresser une demande de compensation
      précisant l'Utilisateur, l’incident concerné et sa durée auprès du Support. ONOFF TELECOM
      s’engage à traiter les demandes de compensation (i) dans les cinq (5) jours ouvrés suivant
      leur réception en ce qui concerne la conformité de la demande de l'Utilisateur aux conditions
      détaillées au présent article et (ii) dans le mois qui suit leur réception pour leur complet
      examen. Si la demande est justifiée, ONOFF TELECOM attribuera à l'Utilisateur affectée une
      compensation équivalente à un (1) mois d’abonnement au Service affecté.
    </p>

    <p>
      Ces demandes de compensation ne sont toutefois pas recevables dans les cas suivants :
    </p>

    <ul>
      <li>
        en cas d’absence de demande de compensation formulée par l'Utilisateur dans le délai d’un
        (1) mois du non-respect des niveaux de services susmentionnés,
      </li>

      <li>
        en cas de faute de l’Utilisateur concerné ou de mauvaise utilisation par l’Utilisateur
        et/ou ses correspondants du Service,
      </li>

      <li> en cas de mauvais paramétrage de l’Application,</li>

      <li> en cas d’utilisation non-conforme de l’Application,</li>

      <li>
        en cas de transmission des signaux radioélectriques affectée par les contraintes ou les
        limites des normes techniques imposées à l’opérateur auprès duquel l’Abonnement Opérateur
        est souscrit ou à l’(aux) opérateur(s) partenaire(s) d’ONOFF TELECOM par les autorités
        réglementaires ou les groupements normatifs compétents,
      </li>

      <li>
        en cas d’opération de maintenance d’ONOFF TELECOM ou du ou des opérateur(s) partenaire(s)
        d’ONOFF TELECOM,
      </li>

      <li> en cas de perturbation ou d’interruption non directement imputable à ONOFF TELECOM,</li>

      <li>
        en cas de perturbation et/ou d’indisponibilité totale ou partielle, et/ou d’interruption
        de tout ou partie des services proposés sur les réseaux de l’opérateur auprès duquel
        l’Abonnement Opérateur est souscrit,
      </li>

      <li> en cas de force majeure.</li>
    </ul>

    <h3>9. Hébergement du contenu des communications</h3>

    <p>
      ONOFF TELECOM s’engage à assurer la sécurité, la confidentialité et l’intégrité des
      communications passées par l’Application conformément aux obligations légales et
      réglementaires applicables.
    </p>

    <p>
      Néanmoins, les contenus stockés, utilisés, transmis et reçus par l’Utilisateur grâce à
      l’Application le sont sous son entière responsabilité. Il appartient à l’Utilisateur de
      veiller à ne pas enfreindre les dispositions légales et réglementaires en vigueur, en
      particulier relatives aux droits de propriété intellectuelle attachés à certains contenus.
    </p>

    <p>
      L’Utilisateur est seul responsable de l’usage qu’il fait ou qu’il entend faire des
      fonctionnalités de l’Application et du Contenu Utilisateur. ONOFF TELECOM n’assure ni le
      contrôle ni la promotion du Contenu Utilisateur. ONOFF TELECOM n’assume aucune responsabilité
      quant à ce Contenu Utilisateur, quel qu’il soit, y compris, et sans s’y limiter, les médias
      partagés via l’utilisation de l’Application. En outre, l’Utilisateur reconnait et accepte
      qu’ONOFF TELECOM n’exerce aucun contrôle a priori sur le Contenu Utilisateur et qu’il n’est
      pas tenu à une obligation générale de surveillance de ces contenus. De même, bien qu’elle ait
      un accès au contenu Utilisateur, ONOFF TELECOM n’exerce aucun contrôle des sites et des
      sources externes vers lesquels redirigent des liens hypertextes accessibles via l’Application.
      ONOFF TELECOM est autorisée à communiquer tout contenu Utilisateur à la requête de toute
      autorité administrative ou judiciaire.
    </p>

    <p>
      Tout Utilisateur a la possibilité de notifier à ONOFF TELECOM tout Contenu Utilisateur
      illicite ou portant atteinte aux droits de l’Utilisateur afin d’en demander le retrait.
      L’Utilisateur sera, dans ce cas, seul responsable de la plainte qu’il aura déposée auprès
      d’ONOFF TELECOM. L’Utilisateur admet et convient que toute plainte déposée par lui pourra être
      utilisée dans le cadre d’une éventuelle procédure judiciaire. L’Utilisateur admet et convient
      également que toute information inexacte, fausse ou trompeuse pourra engager sa responsabilité
      civile et pénale.
    </p>

    <p>
      Toute plainte ou notification accusant à tort des Contenus Utilisateurs de porter atteinte aux
      droits de l’Utilisateur notifiant ou aux droits de tiers, en ayant connaissance de
      l’inexactitude de cette déclaration, rend l’Utilisateur notifiant passible de poursuites.
      L’Utilisateur notifiant pourrait ainsi être condamné au versement de dommages et intérêts, aux
      dépens ou/et aux frais de justice supportés par celui-ci, ainsi que par tout titulaire de
      droits ayant subi un préjudice en raison de la confiance accordée par ONOFF TELECOM à cette
      déclaration inexacte. L’Utilisateur notifiant, dans ce cas, pourra également, le cas échéant,
      faire l’objet de poursuites pénales.
    </p>

    <p>
      L’Utilisateur déclare et garantit :
    </p>

    <ul>
      <li>
        il ne doit publier ou télécharger un Contenu Utilisateur que s’il possède les
        consentements, autorisations et licences nécessaires.
      </li>

      <li>
        il ne doit utiliser, publier ou inclure d’élément personnel à un tiers au sein du Contenu
        Utilisateur que s’il dispose de l’autorisation, du consentement ou de la permission exprès
        de cette personne à cet effet.
      </li>
    </ul>

    <p>
      En publiant ou en téléchargeant du Contenu Utilisateur, l’Utilisateur convient et accepte
      d’accorder à ONOFF TELECOM sur ce contenu une licence mondiale non exclusive, irrévocable,
      libre de droits, susceptible de sous-licence et transférable de tous les droits d’utilisation,
      de modification, de représentation, de traduction, d’exploitation, d’édition, d’inclusion,
      d’enregistrement, d’adaptation, de reproduction et autres. Cette licence est accordée pour
      toute la durée maximale de protection par les droits de propriété intellectuelle du Contenu
      Utilisateur publié ou téléchargé par l’Utilisateur.
    </p>

    <p>
      L'Utilisateur garantit ONOFF TELECOM de toute revendication intentée par des tiers ou
      d’autres utilisateurs au motif que le Contenu Utilisateur constituerait une violation des
      droits de propriété intellectuelle ou industrielle revendiqués par des tiers ou d’autres
      Utilisateurs, ONOFF TELECOM ne pouvant être recherché ou inquiété à ce sujet.
    </p>

    <p>
      En vertu du droit applicable, ONOFF TELECOM se réserve le droit, bien qu’il n’ait aucune
      obligation de contrôler le Contenu Utilisateur, d’accéder audit Contenu Utilisateur à tout
      moment et sans notification préalable, afin de vérifier le respect des Conditions
      d’Utilisation, de vérifier toute violation ou violation présumée des droits de tiers, de
      vérifier la responsabilité d’Utilisateurs suite aux plaintes d’individus et/ou aux demandes
      des autorités, et de prendre les mesures nécessaires, y compris le retrait du Contenu
      Utilisateur, la suspension temporaire ou la résiliation des Services.
    </p>

    <h3>10. Propriété intellectuelle</h3>

    <p>
      L’Application contient des informations exclusives et confidentielles protégées par les lois
      et traités sur la propriété intellectuelle. ONOFF TELECOM conserve la propriété exclusive de
      l’Application et l’Utilisateur s’interdit, directement, indirectement ou par l’intermédiaire
      de tiers, de porter atteinte, mettre en péril ou limiter, par quelque mesure que ce soit, les
      droits de propriété intellectuelle dont dispose ONOFF TELECOM sur l’Application. De même toute
      reproduction, représentation, adaptation, modification, traduction, transformation, diffusion,
      intégration, dans un autre matériel, exploitation commerciale ou non et/ou réutilisation de
      quelque manière que ce soit de tout ou partie des éléments composant l’Application est
      strictement interdite. ONOFF TELECOM, les marques de commerce et les logos associés sont des
      marques commerciales propriété d’ONOFF TELECOM.
    </p>

    <p>
      L’Utilisateur bénéficie, sous réserve de l’acceptation et du respect des Conditions
      d’Utilisation, d’un droit d’usage limité, non exclusif, non transférable, non cessible, à
      titre gratuit, afin de télécharger et installer l’Application sur un terminal, de type
      Smartphone ou tablette et d’utiliser à titre personnel l’Application par le biais de son
      Compte Utilisateur. Ce droit est conféré à l’Utilisateur pour toute la durée du présent
      Contrat et sur l’ensemble du territoire dans lequel ONOFF TELECOM commercialise les Services.
    </p>

    <p>
      L’Utilisateur s’interdit de :
    </p>

    <ul>
      <li>
        concéder la licence, vendre, céder, louer, exporter, importer, distribuer ou transférer ou
        accorder des droits à un tiers sur l’Application ;
      </li>

      <li>
        de quelque manière que ce soit, modifier, copier, décompiler, effectuer l’ingénierie
        inverse, désassembler tout ou partie des éléments composant l’Application, ou encore tenter
        de dériver le code source, ou créer des œuvres dérivées à partir de tout ou partie de
        l’Application ou de ses mises à jour, sauf dans les cas où la loi applicable en disposerait
        autrement ;
      </li>

      <li>
        directement ou indirectement, créer une œuvre dérivée, rechercher le code source ou des
        protocoles des logiciels de l’Application (sauf dans la mesure permise par la loi) ;
      </li>

      <li>
        supprimer, masquer ou modifier les mentions de droit des marques ou d’autres mentions de
        propriété incluses dans l’Application.
      </li>
    </ul>

    <p>
      Toute action ci-dessus énoncée sera considérée comme une violation des droits d’ONOFF TELECOM.
      Toute utilisation non conforme que l’Application pourra entrainer des poursuites judiciaires.
    </p>

    <p>
      Cet article régira toute mise à jour fournie par ONOFF TELECOM qui remplace et/ou s’ajoute à
      l’Application, sauf si celle-ci est accompagnée d’une licence distincte.
    </p>

    <h3>11. Données personnelles</h3>

    <p>
      La collecte et l’utilisation des données personnelles de l’Utilisateur sont définies à la
      Politique de protection des données personnelles.
    </p>

    <p>
      En raison de l’objet du Service et de la confidentialité associée, l’inscription à l’annuaire
      universel ne sera pas effectuée par ONOFF TELECOM. Toutefois, l’Utilisateur peut solliciter
      son inscription à l’annuaire universel par demande expresse adressée à ONOFF TELECOM à
      l’adresse suivante : support@onoffapp.com. Dans ce cas, l’Utilisateur communiquera, sous sa
      responsabilité, ses coordonnées (numéro de téléphone et nom, prénom ou initiales sous réserve
      d’homonymie, raison sociale, adresse postale complète ou non, adresse électronique, profession
      ou activité) pour qu’elles figurent gratuitement dans les listes de ONOFF TELECOM destinées
      aux éditeurs d’annuaires et de services de renseignements.
    </p>

    <h3>12. Conditions financières</h3>

    <p>
      Dans la mesure où l’Application et les Services utilisent l’Abonnement Opérateur,
      l’Utilisateur reconnait et accepte que cette utilisation peut donner lieu à facturation de
      l’Utilisateur par son opérateur dans le cadre de son Abonnement Opérateur. En particulier,
      l’utilisation de l’Application grâce à l’accès Internet haut débit mobile de l’Abonnement
      Opérateur sera décompté et/ ou facturé, selon le cas, dans le cadre de l’Abonnement Opérateur.
      Les appels/SMS/MMS émis et reçus depuis l’Application peuvent être décomptés et/ou facturés,
      selon le cas, dans le cadre de l’Abonnement Opérateur de l’Utilisateur. En cas d’utilisation
      des Services en dehors du territoire où est établi l’Utilisateur, des frais additionnels de
      roaming pourront être facturés par l’opérateur auprès duquel l’Utilisateur a souscrit son
      Abonnement Opérateur.
    </p>

    <p>
      Par souci de clarté et de transparence avec l’Utilisateur, ONOFF TELECOM indique expressément
      que même les appels entrants (c’est-à-dire reçus) depuis l’Application peuvent être, dans
      certains cas, facturés en tant qu’appels sortants (c’est-à-dire émis) dans le cadre de son
      Abonnement Opérateur par l’opérateur auprès duquel il a souscrit son Abonnement Opérateur et
      au tarif en vigueur prévu par ledit opérateur. Ce système permet de garantir à l’Utilisateur
      la confidentialité absolue de ses appels émis et reçus à partir de l’Application.
    </p>

    <p>
      Tous les tarifs applicables aux Services et, en particulier, ceux applicables aux Numéros, aux
      forfaits d'appel et aux Crédits ONOFF, sont mentionnés, régulièrement mis à jour et
      disponibles via l’interface de l’Application. Les Services fournis par ONOFF TELECOM sont
      facturés à l’Utilisateur conformément au prix en vigueur au jour de la souscription du Service
      concerné.
    </p>

    <p>
      En cas de modification des tarifs applicables à un Service souscrit par un Utilisateur pendant
      la durée d’abonnement sélectionnée par l’Utilisateur, ONOFF TELECOM en informera l’Utilisateur
      au moins un mois avant l’entrée en vigueur du tarif modifié. L’Utilisateur pourra alors
      résilier le Service concerné dans les conditions définies à l’article 16.
    </p>

    <p>
      La souscription à chaque Numéro est soumise au paiement d’un prix forfaitaire variable selon
      la durée d’abonnement sélectionnée par l’Utilisateur parmi les durées prédéterminées qui lui
      sont proposées. Les Numéros sont répartis en plusieurs catégories disposant chacune d’un
      barème de prix propre figurant dans l’interface de l’Application.
    </p>

    <p>
      L’émission d’appels téléphoniques vers des numéros fixes ou mobiles et l’envoi de SMS peut
      être subordonnée à l’achat de Crédits ONOFF. L’Utilisateur pourra acheter des Crédits ONOFF au
      prix défini à l’annexe tarifaire en vigueur au jour de l’achat.
    </p>

    <p>
      L’Utilisateur reconnait et convient que :
    </p>

    <ul>
      <li>
        les Crédits ONOFF sont décomptés par incréments d’une minute et arrondis à la minute
        supérieure ;
      </li>

      <li>
        les Crédits ONOFF ont une période de validité de cent quatre-vingt-dix (190) jours à compter
        de leur date d’achat. A l’expiration de cette période, tout Crédit ONOFF qui n’aurait pas
        été utilisé sera définitivement perdu. Toutefois, tout nouvel achat de Crédits ONOFF au
        cours de cette période interrompt ce délai. Une nouvelle période de validité de cent
        quatre-vingt-dix (190) jours recommence à courir à compter de la date à laquelle
        l’Utilisateur a acheté de nouveaux Crédits ONOFF. Cette nouvelle période de validité
        s’applique à l’ensemble des Crédits ONOFF souscrits par l’Utilisateur.
      </li>
    </ul>

    <p>
      Toutes offres promotionnelles qui seraient proposées par ONOFF TELECOM feront l’objet de
      conditions spécifiques qui s’ajouteront aux présentes Conditions d’Utilisation en ce qui
      concerne les conditions qui leur sont spécifiquement applicables.
    </p>

    <h3>13. Conditions de paiement – Modalités de Remboursement</h3>

    <p>
      La souscription à chaque Numéro est facturée en avance pour l’intégralité de la période de
      souscription choisie par l’Utilisateur.
    </p>

    <p>
      La facturation et le paiement des Services sont effectués, selon le cas, par et auprès du
      tiers auprès duquel l’abonnement a été souscrit et/ ou directement par et auprès d’ONOFF
      TELECOM.
    </p>

    <p>
      Lorsque le paiement est réalisé auprès d’un tiers, celui-ci se fait selon les conditions
      contractuelles liant l’Utilisateur à ce tiers.
    </p>

    <p>
      Lorsque le paiement est réalisé directement auprès d’ONOFF TELECOM, celui-ci se fait selon
      l’un des modes de paiement proposés par ONOFF TELECOM.
    </p>

    <p>
      En cas de contestation des sommes facturées, l’Utilisateur devra adresser sa demande de
      remboursement à la société auprès de laquelle il a réalisé le paiement, c’est-à-dire, selon le
      cas, le tiers ou ONOFF TELECOM. La demande de remboursement devra être effectuée, selon le
      cas, conformément aux modalités définies par les conditions de vente du tiers ou, lorsqu’elle
      est adressée directement à ONOFF TELECOM dans les conditions suivantes.
    </p>

    <p>
      Lorsque le paiement des Services a été effectué entre les mains d’ONOFF TELECOM, l’Utilisateur
      disposera de quatre-vingt-dix (90) jours suivant la date de facturation pour contester les
      sommes qui lui ont été facturées en y apportant toutes les justifications nécessaires. Passé
      ce délai, l’Utilisateur ne pourra plus solliciter aucun remboursement.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de refuser toute demande de remboursement s’il considère (a)
      que la demande de l’Utilisateur n’est pas fondée, ou (b) que l’Utilisateur n’a pas respecté
      les Conditions d’Utilisation, ou (c) que l’Utilisateur a utilisé l’Application de façon
      frauduleuse ou que son Compte Utilisateur est utilisé de façon frauduleuse par un tiers. Dans
      ce dernier cas de figure, l’Utilisateur devra exercer son droit de remboursement auprès de sa
      banque.
    </p>

    <h3>14. Modifications des Conditions d’Utilisation</h3>

    <p>
      ONOFF TELECOM informe l'Utilisateur de toute modification des Conditions d’Utilisation
      préalablement à leur entrée en vigueur. Si l'Utilisateur refuse que cette modification
      s’applique à ses Services en cours, il doit résilier son Contrat dans le mois suivant
      l’information de la modification, dans les conditions définies ci-dessous à l’article 15. A
      défaut de résiliation dans ce délai, les Conditions d’Utilisation modifiées s’appliqueront
      automatiquement à l’issue de ce délai.
    </p>

    <h3>15. Date d’effet, durée et résiliation</h3>

    <p>
      Le Contrat prend effet à la date de téléchargement de l’Application par l’Utilisateur et
      restera en vigueur jusqu’à la réalisation cumulatives des deux conditions suivantes : (i) la
      résiliation par l’une des Parties de l’ensemble des Services fournis à l’Utilisateur et (ii)
      la suppression par l’Utilisateur de l’Application.
    </p>

    <p>
      L’Utilisateur reconnaît cependant que la licence, en relation avec le Contenu Utilisateur, est
      concédée pour la durée maximale de protection des droits de propriété intellectuelle du
      Contenu Utilisateur, et continuera donc après l’arrivée du terme du Contrat pour quelque
      raison que ce soit.
    </p>

    <p>
      A moins que le Numéro souscrit par l’Utilisateur ne l’ait été pour une durée ferme et non
      renouvelable, l’abonnement au Numéro se renouvellera automatiquement à la fin de la période
      initialement choisie par l’Utilisateur, sauf résiliation dans les conditions de l’article 17.
      Le renouvellement de l’abonnement entrainera facturation et paiement du montant correspondant.
    </p>

    <p>
      Lorsque le Numéro a été souscrit pour une durée ferme et non renouvelable, l’Utilisateur
      pourra maintenir sa souscription au Numéro manuellement en se connectant à l’Application avant
      l’expiration de la période de souscription en cours. Ce maintien de la souscription donne lieu
      à la prorogation du Contrat selon les Conditions d’Utilisation et les tarifs en vigueur à la
      date du renouvellement manuel de la souscription.
    </p>

    <p>
      L’Utilisateur dispose d'un délai de quatorze jours, à compter de la conclusion du Contrat,
      pour exercer son droit de rétractation, sans avoir à motiver sa décision ni à supporter
      d'autres coûts que ceux prévus aux articles L. 221-23 à L. 221-25 du code de la consommation.
      L’Utilisateur qui souhaite exercer son droit de rétractation informe ONOFF TELECOM de sa
      décision de se rétracter par l'envoi, à l’adresse support@onoffapp.com et avant l'expiration
      du délai susmentionné, du formulaire de rétractation figurant en annexe des Conditions
      d’Utilisation, ou de toute autre déclaration, dénuée d'ambiguïté, exprimant sa volonté de se
      rétracter. Si la souscription des Services a été effectuée via un tiers, l’Utilisateur devra
      exercer son droit de rétractation auprès de ce tiers.
    </p>

    <p>
      Si l’Utilisateur souhaite que l'exécution du Contrat commence avant la fin du délai de
      rétractation susmentionné, il doit en faire la demande expresse à ONOFF TELECOM. L’Utilisateur
      reconnaît et accepte qu’il a été informé de ce droit et que l’utilisation des Services pendant
      la durée du délai de rétractation équivaut à une demande expresse d’exécuter le Contrat avant
      la fin du délai de rétractation.
    </p>

    <p>
      En cas de rétractation, ONOFF TELECOM remboursera tous les paiements reçus, sans retard
      excessif et, en tout état de cause, au plus tard quatorze jours à compter du jour où elle a
      été informée de la décision de rétractation. Le remboursement sera effectué en utilisant le
      même moyen de paiement que celui utilisé pour la transaction initiale, sans frais pour
      l’Utilisateur.
    </p>

    <p>
      Dans le cas où l’Utilisateur exerce son droit de rétractation alors que l'exécution du Contrat
      a commencé à sa demande expresse, avant la fin du délai de rétractation susmentionné, il
      versera à ONOFF TELECOM le montant correspondant aux Services fournis jusqu'à la communication
      de sa décision de se rétracter. Ce montant sera proportionné au prix total convenu dans le
      Contrat. En outre, ONOFF TELECOM pourra facturer les éventuels usages effectués pendant le
      délai de rétractation.
    </p>

    <p>
      Si l’Utilisateur ne demande pas expressément que l’exécution du Contrat commence avant la fin
      du délai de rétractation susmentionné, il reconnait et accepte que l’Application soit
      indisponible pendant toute la durée du délai de rétractation (quatorze (14) jours).
    </p>

    <h3>16. Résiliation</h3>

    <p>
      Chaque Partie a la possibilité de mettre fin au Contrat à tout moment. Pour ce faire, il
      suffit à l’Utilisateur de résilier son (ses) Numéro(s) directement dans l’Application ou de
      demander la clôture de son Compte Utilisateur directement dans l’Application.
    </p>

    <p>
      Il est rappelé que si les Services ont été souscrits via un tiers, leur fourniture est
      également soumise aux conditions de vente de ce tiers, en plus des présentes Conditions
      d’Utilisation. Dans ce cas, la résiliation des Services devra être effectuée directement
      auprès de ce tiers dans les conditions définies par ses conditions de vente.
    </p>

    <p>
      La résiliation d’un Numéro est effectuée par l’Utilisateur dans son Compte Utilisateur
      disponible sur l’interface de l’Application. La résiliation du Numéro entrera en vigueur le
      lendemain du dernier jour de la période d’abonnement en cours. Dans le cas où les Services ont
      été souscrits via un tiers, la résiliation d’un Numéro réalisée depuis le Compte Utilisateur
      n’entraine l’arrêt des paiements que si l’Utilisateur a informé ce tiers de sa résiliation
      conformément aux conditions de vente de ce tiers.
    </p>

    <p>
      Chaque Partie pourra en outre résilier le Contrat pour faute de l’autre Partie huit (8) jours
      après l’envoi d’une mise en demeure restée vaine, celle-ci résultant du seul manquement de la
      partie fautive. Il est convenu que les cas suivants pourront entrainer la résiliation de plein
      droit du Contrat par ONOFF TELECOM :
    </p>

    <ul>
      <li>
        si l’Utilisateur a contrevenu aux Conditions d’Utilisation ;
      </li>

      <li>
        si les informations renseignées par l’Utilisateur dans son Compte Utilisateur sont inexactes
        ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne une utilisation illégale ou frauduleuse de l’Application ou
        contraire aux droits de tiers ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne que l’Utilisateur essaye de tirer profit indûment de l’une des
        politiques d’ONOFF TELECOM, quelles qu’elles soient, ou de les utiliser à mauvais escient.
      </li>
    </ul>

    <p>
      ONOFF TELECOM pourra mettre fin à sa relation avec l'Utilisateur, à tout moment sous réserve
      du respect d’un préavis minimum d’un (1) mois avant l’arrivée du terme de la durée
      d’engagement.
    </p>

    <p>
      Si ONOFF TELECOM décide de ne plus proposer un Service particulier, il pourra résilier ledit
      Service sans pénalité, sous réserve du respect d’un préavis minimum d’un mois.
    </p>

    <p>
      Toute résiliation d’ONOFF TELECOM sera notifiée à l'Utilisateur par voie d’e-mail.
    </p>

    <p>
      L'Utilisateur comprend et accepte de ne pas utiliser un Numéro dans les conditions définies
      dans les Conditions Générales d'Utilisation entraine la résiliation automatique et de plein
      droit du Numéro ainsi que des données relatives à l’utilisation du Numéro (par exemple : SMS,
      journal des appels, messages vocaux, etc.).
    </p>

    <p>
      A la date de prise d’effet de la résiliation du Contrat, celle-ci aura les conséquences
      suivantes :
    </p>

    <ul>
      <li>
        Toutes les licences et droits d’utilisation de l’Application prendront fin ;
      </li>
      <li>
        L’Utilisateur devra cesser d’utiliser l’Application ;
      </li>
      <li>
        L’Utilisateur devra supprimer l’Application de tous les disques durs, réseaux et supports de
        stockage, et en détruira toutes les copies en sa possession, ou sous son contrôle ;
      </li>
      <li>
        ONOFF TELECOM pourra bloquer l’accès au Compte Utilisateur et/ ou à l’Application ;
      </li>
      <li>
        ONOFF TELECOM pourra effacer les données associées au Compte Utilisateur.
      </li>
    </ul>

    <p>
      La résiliation d’un Service particulier aura les mêmes conséquences que celles susmentionnées,
      lesquelles seront limitées au seul Service résilié.
    </p>

    <h3>17. Portabilité</h3>

    <p>
      L’Utilisateur qui souhaite transférer l’usage du Numéro, vers un autre opérateur mobile doit
      demander la portabilité de son Numéro auprès de son nouvel opérateur. La portabilité des
      Numéros est possible, sous réserve d’éligibilité technique. L’attention de l’Utilisateur est
      attirée sur le fait qu’une demande de portabilité sortante vaut demande de résiliation du
      Numéro et entraîne, au terme de la procédure correspondante, la résiliation de plein droit des
      Services associés audit Numéro, sans préjudice de l’éventuelle durée d’engagement ferme de
      l’Utilisateur au titre des Services. ONOFF TELECOM demeure en tout état de cause étrangère aux
      relations contractuelles entre l’Utilisateur et le nouvel opérateur. La responsabilité d’ONOFF
      TELECOM ne saurait être engagée à raison de faute, d’inexécution, de défaillances ou de
      dysfonctionnements imputables à l’Utilisateur ou au nouvel opérateur et qui auraient pour
      effet de retarder, de perturber ou d’empêcher ce portage. Dans ces hypothèses, la
      responsabilité d’ONOFF TELECOM ne saurait être engagée du fait de l’interruption de service
      lié au portage du Numéro vers le nouvel opérateur.
    </p>

    <p>
      Lors de la souscription des Services auprès d’ONOFF TELECOM, l’Utilisateur peut solliciter la
      reprise par ONOFF TELECOM de son numéro mobile actuel. La portabilité entrante d’un numéro
      mobile est possible, sous réserve d’éligibilité technique. Le numéro mobile visé doit être
      associé à une ligne téléphonique active jusqu’au transfert dudit numéro. Lors de sa
      souscription aux Services, l’Utilisateur doit transmettre le numéro à conserver à ONOFF
      TELECOM, la date de portage souhaitée ainsi que toutes les données requises par l’ancien
      opérateur et associées audit numéro. L’Utilisateur mandate ONOFF TELECOM afin d’effectuer
      l’ensemble des actes nécessaires à la mise en œuvre de sa demande de portabilité et en
      particulier de résilier en son nom et pour son compte le contrat qu’il détient auprès de son
      ancien opérateur relatif à ce numéro. Cette résiliation prendra effet avec le transfert
      effectif du numéro de l’Utilisateur vers ONOFF TELECOM.
    </p>

    <p>
      Dans ce cadre, ONOFF TELECOM informe l’Utilisateur des conditions d’éligibilité, ainsi que des
      conséquences de sa demande : la demande de portabilité du numéro vaut demande de résiliation
      du contrat de l’Utilisateur auprès de son précédent opérateur, sans préjudice des stipulations
      contractuelles relatives aux durées d’engagement. L’Utilisateur reste tenu des obligations qui
      le lient à son précédent opérateur, notamment de tout paiement éventuellement restant. Le
      portage effectif du numéro entraîne la résiliation du contrat qui lie l’Utilisateur avec son
      précédent opérateur. La portabilité n’emporte pas transfert des services dont l’Utilisateur
      bénéficiait au titre du contrat souscrit antérieurement auprès de son précédent opérateur. A
      ce titre l’attention de l’Utilisateur est attirée sur le fait que les Services requièrent
      l’existence d’un Abonnement Opérateur en vigueur. L’Utilisateur est donc clairement informé
      que si sa demande de portabilité entraine la résiliation de l’Abonnement Opérateur, il ne
      pourra pas utiliser les Services.
    </p>

    <p>
      ONOFF TELECOM pourra refuser de donner suite à une demande de portabilité dans les cas
      suivants :
    </p>

    <ul>
      <li>
        incapacité du demandeur ;
      </li>

      <li>
        demande de portabilité incomplète ou contenant des informations erronées ;
      </li>

      <li>
        non-respect des règles de gestion du plan national de numérotation : la demande de
        conservation doit respecter la correspondance entre le numéro objet de la demande et
        l’implantation géographique du demandeur ;
      </li>

      <li>
        numéro du mobile, objet de la demande de portabilité, inactif au jour du portage ;
      </li>

      <li>
        numéro du mobile, objet de la demande de portabilité, faisant déjà l’objet d’une demande de
        portabilité non encore exécutée.
      </li>
    </ul>

    <h3>18. Suspension</h3>

    <p>
      Sans préjudice de la possibilité de résilier le Contrat, ONOFF TELECOM pourra suspendre les
      Services ou l’accès à l’Application de l'un des Utilisateurs sur simple information écrite de
      l’Utilisateur concerné dans les cas suivants :
    </p>

    <ul>
      <li>
        si l’Utilisateur a contrevenu aux Conditions d’Utilisation ;
      </li>

      <li>
        si les informations renseignées pour l’Utilisateur dans son Compte Utilisateur sont
        inexactes, incomplètes ou si le numéro mobile de l’Utilisateur fourni par l’opérateur auprès
        duquel l’Abonnement Opérateur a été souscrit n’est plus valide ;
      </li>

      <li>
        si les conditions de territorialité requises pour l’attribution du Numéro à l’Utilisateur ne
        sont plus remplies
      </li>

      <li>
        si ONOFF TELECOM soupçonne une utilisation illégale ou frauduleuse ou anormale de
        l’Application ou contraire aux droits de tiers ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne que l’Utilisateur essaye de tirer profit indûment de la politique
        de remboursement ou de l’une des autres politiques d’ONOFF TELECOM, quelles qu’elles soient,
        ou de les utiliser à mauvais escient ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne que le Compte Utilisateur fait l’objet d’une utilisation
        frauduleuse par un tiers.
      </li>
    </ul>

    <p>
      ONOFF TELECOM est également susceptible d’interrompre l’utilisation de l’Application dans
      certaines conditions incluant, de façon non-exhaustive, l’impossibilité d’assurer le Service,
      des problèmes d’origine externe qui rendent la mise en place du Service difficile ou
      imprudente, les progrès technologiques, la nécessité de modifier l’Application ou les
      Services.
    </p>

    <h3>19. Garantie et Responsabilité</h3>

    <p>
      ONOFF TELECOM s’engage à apporter toute la compétence et le soin nécessaires à la fourniture
      des Services et à mettre en œuvre toutes les dispositions nécessaires afin d’assurer les
      Services de manière permanente et continue. A ce titre ONOFF TELECOM n’a qu’une obligation de
      moyens.
    </p>

    <p>
      Au regard des dispositions légales pertinentes et applicables, l’Application est fournie « en
      l’état » et sera utilisée aux propres risques de l’Utilisateur, sans aucune garantie. En ce
      sens, l’Utilisateur est seul responsable de la préservation, de la sécurité et de l’intégrité
      des données, matériels et logiciel, ainsi que de la confidentialité de ses mots de passe et
      identifiants de Compte Utilisateur et autres dans le cadre de l’utilisation des Services.
    </p>

    <p>
      ONOFF TELECOM n’assume aucune responsabilité envers tout tiers ou envers l’Utilisateur
      concernant les contenus, ces derniers étant les seuls responsables du Contenu Utilisateur
      soumis, créé, affiché ou publié grâce à l’Application.
    </p>

    <p>
      ONOFF TELECOM fait ses meilleurs efforts pour assurer la confidentialité des informations et
      des contenus publiés grâce à l’Application.
    </p>

    <p>
      ONOFF TELECOM ne garantit pas que l’Application sera toujours disponible, accessible,
      ininterrompue, fiable, et exempte d’erreurs. En outre, aucune garantie n’est offerte
      concernant les connexions ou transmissions internet, ou à la qualité des appels passés au
      moyen de l’Application.
    </p>

    <p>
      S’il advenait qu’un tiers porte plainte contre ONOFF TELECOM en relation avec, ou à la suite
      de (i) tout manquement de la part de l’Utilisateur aux Conditions d’Utilisation, (ii) tout
      manquement de la part de l’Utilisateur à toute loi ou réglementation applicable, (iii) toute
      violation de la part de l’Utilisateur des droits des tiers (y compris les droits de propriété
      intellectuelle), (iv) ses Contenus Utilisateur ou (v) sa plainte concernant tout Contenu
      Utilisateur, l’Utilisateur garantira ONOFF TELECOM de tous dommages, responsabilités, pertes,
      condamnation, coûts et dépenses (y compris les frais juridiques et autres coûts raisonnables)
      qui en découleraient.
    </p>

    <p>
      Sous réserve de ce qui précède et conformément à la loi applicable, ONOFF TELECOM ne saurait
      être tenue responsable envers l’Utilisateur, qu’ONOFF TELECOM ait ou non été informée de la
      possibilité de tels dommages ou perte, pour :
    </p>

    <ul>
      <li>
        des dommages indirects et notamment tout dommage, perte, ou corruption de données;
      </li>

      <li>
        des dommages résultant des faits des tiers ou des cas de force majeure;
      </li>

      <li>
        toute réclamation, tout dommage ou perte résultant de, ou lié à :
        <ul>
          <li>
            l’incapacité de l’Utilisateur à utiliser tout ou partie de l’Application ou de sa
            fonctionnalité ;
          </li>

          <li>
            l’incapacité de l’Utilisateur à prendre les dispositions nécessaires pour rentrer en
            contact avec les services d’urgence ;
          </li>

          <li>
            l’incapacité de l’Utilisateur à fournir des informations précises sur l’emplacement
            physique à un service d’urgence ;
          </li>

          <li>
            la conduite des agents de service d’urgence tiers et des centres d’appels avec lesquels
            l’Utilisateur est mis en relation.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      Les Services ne se substituant en aucun cas au service primaire de téléphonie mobile fourni
      par l’opérateur auprès duquel un Abonnement Opérateur a été souscrit, l’Utilisateur reconnait
      et accepte que les Numéros ne permettent pas d’émettre des appels vers toutes les classes de
      numéros. En conséquence, les appels ne pouvant être émis depuis l’Application (c’est-à-dire
      notamment les appels vers les numéros spéciaux, les numéros internationaux et les numéros
      d’urgence) devront être émis directement depuis le téléphone de l’Utilisateur. ONOFF TELECOM
      se réserve le droit de permettre aux Utilisateurs d’émettre des appels vers des classes de
      numéros additionnelles postérieurement au lancement des Services.
    </p>

    <p>
      En complément de ce qui précède, ONOFF TELECOM ne saurait être tenue responsable envers
      l’Utilisateur, que ONOFF TELECOM ait ou non été informée de la possibilité de tels dommages ou
      perte, pour :
    </p>

    <ul>
      <li>
        tout dommage indirect, et notamment, toute perte de revenu, de contrats, de bénéfices réels
        ou prévus, de chance, d’image de marque ou de réputation, de clientèle ou de de chiffre
        d’affaires ;
      </li>

      <li>
        tout dommage ou toute corruption de données ;
      </li>

      <li>
        toute réclamation, tout dommage ou perte résultant de, ou lié à :
      </li>

      <li>
        l’incapacité de l’Utilisateur à utiliser tout ou partie de l’Application ou de sa
        fonctionnalité ;
      </li>

      <li>
        l’incapacité de l’Utilisateur à prendre les dispositions nécessaires pour rentrer en contact
        avec les services d’urgence
      </li>

      <li>
        l’incapacité de l’Utilisateur à fournir des informations précises sur l’emplacement physique
        à un service d’urgence ;
      </li>

      <li>
        la conduite des agents de service d’urgence tiers et des centres d’appels avec lesquels
        l’Utilisateur est mis en relation.
      </li>

      <li>
        tout dommage au-delà d’un montant, tous chefs de préjudices confondus, correspondant au
        montant total payé par l’Utilisateur pour les Services au cours de la période de douze (12)
        mois précédant immédiatement la date de survenance du fait à l’origine de la demande en
        cause.
      </li>
    </ul>

    <p>
      Dans la mesure où l’Utilisateur démontrerait avoir subi un préjudice direct, la
      responsabilité d’ONOFF TELECOM sera expressément limitée à cent pour cent (100,00%) des sommes
      effectivement perçues par d’ONOFF TELECOM au titre du Contrat lors des six (6) derniers mois
      précédents la survenance dudit préjudice.
    </p>

    <p>
      Les actions en responsabilité à l’encontre d’ONOFF TELECOM se prescrivent par deux ans à
      compter du fait générateur du préjudice.
    </p>

    <h3>20. Force majeure</h3>

    <p>
      La responsabilité d’ONOFF TELECOM ne peut pas être engagée au cas où l’inexécution résulte
      d’un cas de force majeure.
    </p>

    <p>
      Sont considérés comme cas de force majeure, dès lors qu’ils ne sont pas le fait d’ONOFF
      TELECOM et qu’ils échappent à son contrôle, les intempéries exceptionnelles, les catastrophes
      naturelles, les incendies et inondations, la foudre, les surtensions électroniques, les
      attentats, grèves internes ou externes, défaillances ou pannes internes ou externes telles que
      les défaillances liées au réseau téléphonique, aux réseaux des prestataires d’ONOFF TELECOM,
      et toutes celles émanant de l’opérateur de téléphonie mobile auprès duquel l’Utilisateur a
      souscrit son Abonnement Opérateur, et d’une manière générale tout évènement irrésistible et
      imprévisible ne permettant pas le bon fonctionnement de l’Application.
    </p>

    <h3>21. Résolution des litiges – loi applicable</h3>

    <p>
      ONOFF met à la disposition des Utilisateurs un service d’assistance accessible par e-mail à
      l’adresse électronique suivante : support@onoffapp.com. Toute réclamation doit être formulée
      par l’envoi d’un e-mail à l’adresse électronique suivante : support@onoffapp.com. ONOFF
      TELECOM s’engage à apporter une réponse à toute contestation ou demande de remboursement sous
      trente (30) jours ouvrés à partir de la date de réception de celle-ci. En cas de non-respect
      de ce délai, ONOFF TELECOM s’engage, sur demande de compensation justifiant du non-respect de
      cet engagement à l’adresse suivante : support@onoffapp.com, à attribuer à l’Utilisateur
      affecté une compensation équivalente à un (1) mois d’abonnement au Service concerné.
    </p>

    <p>
      En cas de litige découlant notamment de l’interprétation ou l’exécution du Contrat, les
      parties s’efforceront de rechercher une solution amiable. Tout Utilisateur établi en France
      est informé qu’il peut saisir gratuitement le Médiateur des communications électroniques sur
      son site internet : www.mediateur-telecom.fr de tout différend avec ONOFF TELECOM.
    </p>

    <p>
      En tout état de cause, l’Utilisateur peut saisir toute juridiction compétente en cas de litige
      découlant de l’interprétation ou l’exécution du Contrat.
    </p>

    <p>
      Les Conditions d’Utilisation et les modalités de fourniture des Services sont soumises au
      droit français.
    </p>

    <h2>MODÈLE DE FORMULAIRE DE RÉTRACTATION</h2>

    <p>
      Veuillez compléter et renvoyer le présent formulaire uniquement si vous souhaitez vous
      rétracter du Contrat.
    </p>
    <p>
      A l'attention de : ONOFF TELECOM
    </p>
    <p>
      Email : support@onoffapp.com
    </p>
    <br />
    <p>
      Je/nous (1) vous notifie/notifions(1) (*) par la présente ma/notre (1) (*) rétractation du
      contrat portant sur la souscription de services ci-dessous : (2)
    </p>
    <p>
      Commandé le : (2)
    </p>
    <p>
      Nom du (des) consommateur(s) : (2)
    </p>
    <p>
      Adresse e-mail du (des) consommateur(s) : (2)
    </p>
    <p>
      Numéros de téléphones onoff: (2)
    </p>
    <p>
      Signature du (des) consommateur(s) (uniquement en cas de notification du présent formulaire
      sur papier) :
    </p>
    <p>
      Date : (2)
    </p>
    <p>
      (1) Rayez la mention inutile.
    </p>
    <p>
      (2) A compléter par l’utilisateur.
    </p>

  </>
);

export default FrenchText;
